import React, { useState, useEffect,useCallback } from "react";
import AddIcon from "@mui/icons-material/AddComment";
import httpRequest from "../../HttpService";
import {Button,ButtonGroup} from '@mui/material';
import "devextreme/dist/css/dx.dark.css";
import DataGrid, {
  Column,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import EditItemDialog from "./EditItemDialog";

const TripPiecesList = (props) => {
  const [tripPiecesList, setTripPiecesList] = useState([]);
  //const [tableHeight, setTableHeight] = useState(150);
  const [dialogIsOpen, setDialogIsOpen] = useState(false); 
  const [selectedItem, setSelectedItem] = useState({});
  const [defaultItem, setDefaultItem] = useState({});

  const getTripPieces = useCallback(async () => {
    var pList = [];
    httpRequest.getTripPieces(props.accessToken, props.jobId).then((body) => {
      console.log("=========getTripPieces-" + JSON.stringify(body.data));
      if(body.data.length==3){
        if (body.data[1].length !== 0) {
          body.data[1].forEach((value,index) => {     
            if(value.pieceNumber !== null){   
              value.id = index
              pList.push(value);
            }          
          });      
          setTripPiecesList(pList);
          //setTableHeight(100 + 36 * pList.length);
          //console.log("selectedItem variableDefinition-" + selectedItem.variableDefinition)
        }
        if (body.data[2].length === 1) {
          setDefaultItem(body.data[2][0])
        }
      }
      if(body.data.length==2){
        if (body.data[1].length === 1) {
          setDefaultItem(body.data[1][0])
        }
      }

    });
  })

  useEffect(() => {
    console.log("=========getTripPieces-defaultItem--" + JSON.stringify(defaultItem));
  }, [defaultItem])

  useEffect(() => {
    getTripPieces();
  }, []);

  const renderDrNum = (params) => {
    return <a style={{ color: "#13ACB1" }}>{params.value} </a>;
  };

  const updateItem = (e) => {
    console.log("updateItem --" + JSON.stringify(e.data));
    setSelectedItem(e.data);
    setDialogIsOpen(true);     
  }

  const submitRequest = (varJson) =>{
    console.log("submitRequest --" + varJson);       
    const content = {
      pieceNumber : selectedItem.pieceNumber !== null? selectedItem.pieceNumber: null,
      varJSON:  varJson       
    }
    httpRequest.updateTripPieces(props.accessToken,props.jobId,content).then((body) =>{
      console.log("updateTripPieces " + JSON.stringify(body.data));
      setDialogIsOpen(false);
      getTripPieces();
    })
    .catch(function (err) {
        console.log('updateTripPieces' + err);
        setDialogIsOpen(false);
        getTripPieces();          
    }) 
    
  }
/*
"pieceID": 3,
			"pieceNumber": 3,
			"": "14994580",
			"drNum": null,
			"driverNote": null,
			"description": null,
			"weight": 10,
			"length": 20,
			"height": 25,
			"width": 15,
			"cubic": null,
			"skidPositionCount": null,
piece Number
length
width
height
weight
cubic
skid position count
description
driverNumber
driverNote
   */

  return (
    <div
      className="darkDataGrid"
      style={{  width: "100%" }}
    >    
      <ButtonGroup  size="small" variant="outlined" aria-label="outlined button group" style={{ marginBottom:10 }}>                       
        <Button onClick={()=>{ setSelectedItem(defaultItem); setDialogIsOpen(true)}} color="primary" style={{ color: "#007E82", borderColor: "#007E82" }}>
          <AddIcon style={{ verticalAlign: "middle", transform: "scale(0.8)" }}/>
            Add Piece
        </Button>
      </ButtonGroup>
      <DataGrid
        dataSource={tripPiecesList}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        keyExpr="id"
        rowAlternationEnabled={true}
        onCellDblClick ={updateItem}    
      >
        <Paging defaultPageSize={15} />
        {/* <SearchPanel visible={true} width={240} placeholder="Search..." /> */}
        <Pager
          visible={true}
          showInfo={true}
          infoText="Page #{0}. Total: {1} ({2} items)"
          displayMode="full"
        />
        {/* <Column dataField="pieceID" caption="Piece ID" alignment="center" width={80} /> */}
        <Column dataField="pieceNumber" caption="Piece Number" alignment="center" width={110} />
        {/* <Column dataField="jobID" caption="jobID" alignment="center" width={100}/> */}
        <Column dataField="length" caption="Length" alignment="center" width={100} />
        <Column dataField="width" caption="Width" alignment="center" width={100} />
        <Column dataField="height" caption="Height" alignment="center" width={110} />
        <Column dataField="weight" caption="Weight" alignment="center" width={110} />
        <Column dataField="cubic" caption="Cubic" alignment="center" width={100}/>
        <Column dataField="skidPositionCount" caption="skid Position Count" width={160} alignment="center" />
        <Column dataField="description" caption="Description" alignment="center" width={140} />
        <Column dataField="drNum" caption="Driver Num" alignment="center" cellRender={renderDrNum} width={110} />        
        <Column dataField="driverNote" caption="driver Note" alignment="center" width={140} />
       
       
      </DataGrid>
      <EditItemDialog  accessToken={props.accessToken} jobId={props.jobId} variableDefinition={selectedItem?.variableDefinition}  open={dialogIsOpen} 
        description= { selectedItem?.pieceNumber === null ?"Add New Piece" : selectedItem?.description} submitRequest={submitRequest} 
        handleDialogClose={() => {setDialogIsOpen(false);}} />  
    </div>
  );
};

export default TripPiecesList;