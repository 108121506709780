import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import {Typography} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import styles from '../../../components/inputFields/styles.js';
import CustomIconButton from "../../../components/inputFields/CustomIconButton.js";

import "animate.css/animate.css";

const TagNumberFieldsEditable = (props) => {
  const minNum = props.minNum;
  const maxNum = props.maxNum;
  const [numValue, setNumValue] = useState(props.value || 0);
  const [tipText, setTipText] = useState(props.prompt);
  const [warningMsg, setWarningMsg] = useState('');


    useEffect(() => {
      //console.log("**********TagNumberFielsEditable**********" + props.value);
      //setNumValue(props.value)
      if (minNum !== undefined || maxNum !== undefined) {
        if (minNum !== undefined && maxNum !== undefined) {
          setNumValue(props.value< minNum? minNum: props.value> maxNum? maxNum: props.value);
          setTipText( props.prompt?`${props.prompt} Number must be greater then ${minNum}, and less then ${maxNum}.`:
          `Number must be greater then ${minNum}, and less then ${maxNum}.`);
        } else if (minNum !== undefined) {
          setTipText( props.prompt?`${props.prompt} Number must be greater then ${minNum}.`:`Number must be greater then ${minNum}.`);
          setNumValue(props.value<minNum? minNum: props.value);
        } else if (maxNum !== undefined) {
          setTipText(props.prompt? `${props.prompt} Number must be less then ${maxNum}.` :`Number must be less then ${maxNum}.`)
          setNumValue(props.value> maxNum? maxNum: props.value);
        }
      }
      else
        setNumValue(props.value);
    },[props.value]); 
    
    useEffect(() => {
      //console.log("**********TagNumberFielsEditable**********" + numValue);
      try{
        let num = parseInt(numValue);
        if(num>maxNum) setWarningMsg(`Number must be less then ${maxNum}`);
        else if(num<minNum) setWarningMsg(`Number must be greater then ${minNum}`);
        else setWarningMsg('');
      }catch(ex){}
    },[numValue]);  

  
  const handleChange = (event) => {   
    console.log("TagNumberFiels handleChange", event);
    if (event.target.value === "") {
      setNumValue(0);
      props.onChange({
        name: props.param,
        value: 0
    });
    }
    else{
      try{
        let num = parseInt(event.target.value);   
        if (minNum !== undefined && maxNum !== undefined) {
          num = num>maxNum? maxNum:num;
          setNumValue(num.toString());
        }
        else
          setNumValue(num.toString());
        props.onChange({
            name: props.param,
            value: num,
        });
      }
      catch(ex){
        setNumValue(event.target.value);
      }
    }
    
  };

  /*
  const handleBlur = (event) => {    
    console.log("TagNumberFiels handleBlur", event);
    let num = parseInt(event.target.value);
    try{
      if (minNum !== undefined && maxNum !== undefined) {
          num = num<minNum? minNum:num;
          setNumValue(num.toString());      
          if (num <= maxNum && num >= minNum) {     
              props.onChange({
                name: props.param,
                value: num,
              });
          }  
      } 
      else{
        setNumValue(num.toString());
        props.onChange({
          name: props.param,
          value: num,
        });
      } 
    }
    catch(ex){
      console.log("TagNumberFiels exception", ex.toString());
      setNumValue(num.toString());
      props.onChange({
          name: props.param,
          value: num,
      });
    } 
  };
  */

  const clearNum = () => {
    setNumValue(0);
    props.onChange({
      name: props.param,
      value: 0,
    });
  };

  
  return (    
    <Grid container>
      <Grid item xs={11} sm={11}>       
        <Tooltip title={tipText} placement="top" arrow>
        <div className="tagField">
        <TextField
          label={props.label}
          size="small"
          variant="outlined"
          type="number"
          value={numValue}
          onChange={handleChange}
          //onBlur={handleBlur}
          style={styles.formControlNoColor}
          inputProps={{
            style: {
              height: 40             
            },
          }}         
        />
        </div>
        </Tooltip>
        <Typography style={{ marginTop: 15 }} color="error"> {warningMsg} </Typography>
      </Grid>
      <Grid item xs={1} sm={1}>
        <CustomIconButton
          icon={props.icon || "ClearIcon"}
          tooltip={props.iconTooltip || "Clear"}
          onClick={clearNum}
          color={props.color}
          style={{ height:55 }} 
        />
      </Grid>
    </Grid>
  );
};

export default TagNumberFieldsEditable;
