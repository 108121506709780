import React from 'react';
import CustomDialog from '../../components/dialog/Dialog'; // Adjust the import path as needed
import TripFunctionDialog from './tripFunctionDialog';

const DialogManager = ({ 
  openUpdatingDialog,
  locationUpdateDialog,
  updatingItemStr,
  updateGPSSource,
  latestUpdatedPosition,
  setOpenUpdatingDialog,
  setLocationUpdateDialog,
  loadTripDetails,
  updateGPS,
  jobId,
  dialogIsOpen,
  tripFunction,
  handleDialogClose
}) => {
  return (
    <>
      <CustomDialog
        open={openUpdatingDialog}
        onClose={() => setOpenUpdatingDialog(false)}
        hasCloseBtn={false}
        dialogMessage={updatingItemStr}
        dialogTitle="Warning"
        dialogAction={() => {
          setOpenUpdatingDialog(false);
          setTimeout(() => { loadTripDetails(jobId); }, 1500);
        }}
      />
      <CustomDialog
        open={locationUpdateDialog}
        onClose={() => setLocationUpdateDialog(false)}
        hasCloseBtn={true}
        dialogTitle="Warning"
        dialogMessage={
          updateGPSSource === "dropman" ? "Are you sure to update GPS Coordinates?" : 
          updateGPSSource === "photo" ? "Are you sure to update GPS Coordinates to the same as photo location?" : 
          "Are you sure to update GPS Coordinates to the same as signature location?"
        }
        dialogAction={() => {
          setLocationUpdateDialog(false);
          if (latestUpdatedPosition === null) return;
          updateGPS();
        }}
      />
      <TripFunctionDialog
        tripFunction={tripFunction}
        open={dialogIsOpen}
        handleDialogClose={handleDialogClose}
      />
    </>
  );
};

export default DialogManager;