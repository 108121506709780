import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import httpRequest from "../../HttpService";
//import Carousel from '../../components/carouselPanel';
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import Button from "@material-ui/core/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import CustomDialog from "../../components/dialog/Dialog";
import TextInput from "../../components/inputFields/TextInput";

import { Snackbar, Alert } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import "../jobDetail.css";
import CopyIcon from "@material-ui/icons/FileCopy";
import copy from "copy-to-clipboard";
import {AttachFile, Description, PictureAsPdf, Theaters, Image, Audiotrack,} from "@material-ui/icons";
import { saveAs } from "file-saver";
import moment from "moment";
//import ReactPlayer from "react-player";
// const reactAzureMp = require('react-azure-mp');
// const { AzureMP } = reactAzureMp;

export default function WDGallery(props) {
  const [selectedPhoto, setSelectedPhoto] = useState(props.photoArray[0]);
  const [openDialog, setOpenDialog] = useState(false);
  const [base64TextString, setBase64TextString] = useState(null);
  const [pictureComment, setPictureComment] = useState("");
  const [photoList, setPhotoList] = useState(props.photoArray);
  const [fileURL, setFileURL] = useState("");
  const [fileType, setFileType] = useState("");
  const [isPreviewON, setIsPreviewON] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileEXT, setSelectedFileEXT] = useState(null);
  const [fileObjects, setFileObjects] = useState([]);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [messageType, setMessageType] = React.useState(undefined);
  const [isUploadFile, setIsUploadFile] = useState(false);

  const useStyles = makeStyles(() => ({
    previewContainer: {
      container: "true",
      width: "100%",
      height: "100%",
    },
    preview: {
      //width: '100%',
      //height: '100%',
      //item: 'true',
      xs: "12",
    },
    previewImg: {
      //height: '100%',
      //width: '100%',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    //console.log("photoList is " + JSON.stringify(props.photoArray));
    setPhotoList(props.photoArray);
    if (props.photoArray.length > 0) {
      setFileURL(props.photoArray[0].url);
      setFileType(props.photoArray[0].fileType);
      //setSelectedPhoto(props.photoArray[0])
    }
  }, [props.photoArray]);

  useEffect(() => {
    try {
      let imageList = [];
      httpRequest.getTripDocuments(props.accessToken, props.jobId).then(async (response) => {
          console.log(" useEffect getPhotoURLs--" + JSON.stringify(response.data));
          await response.data.forEach((value) => {
            value.savedTimeUTC = moment(value.savedTimeUTC).format("YY-MM-DD HH:mm");
            value.id = imageList.length;
            value.fileType = value.url.endsWith("png") || value.url.endsWith("jpg") ||value.url.endsWith("jpeg") || value.url.endsWith("bmp")? "image" : value.url.substring(value.url.lastIndexOf(".") + 1);
            value.fileExt = value.url.substring(value.url.lastIndexOf(".") + 1);
            //console.log("PhotoURLs--ext is " + value.url.substring(value.url.lastIndexOf(".") + 1));
            imageList.push(value);
          });
          console.log("useEffect imageList--" + JSON.stringify(imageList));
          setPhotoList(imageList);
          if (imageList.length > 0) {
            console.log( " useEffect imageList--" + JSON.stringify(imageList[0]));
            //setFileURL(imageList[0].url);
            setFileType(imageList[0].fileType);
            setSelectedPhoto(imageList[0]);

            if (imageList[0].fileExt === "pdf" ||imageList[0].fileExt === "doc" ||imageList[0].fileExt === "docx" ||imageList[0].fileExt === "xls" ||imageList[0].fileExt === "xlsx" ||imageList[0].fileExt === "csv"){
              var file_url = "https://drive.google.com/viewerng/viewer?embedded=true&url=" + imageList[0].url + "#toolbar=0&scrollbar=0";
              setFileURL(file_url);
            } else setFileURL(imageList[0].url);
          }
          setIsUploadFile(false);
        });
    } catch (e) {
      console.log("gallery useEffect error:" + e);
    }
  }, [isUploadFile]);

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
    };

    console.log("handlePreviewIcon-" + fileObject.file.type);
    //console.log("handlePreviewIcon-" + fileObject.data);

    if (fileObject.file.type.startsWith("image/")) {
      setFileURL(fileObject.data);
      setFileType("image");
    } else {
      setFileURL("");
      setFileType("");
    }
    if (fileObject.file.type.startsWith("image/"))
      return <Image {...iconProps} />;
    // <img src={fileObject.data} {...iconProps}/>

    if (type.startsWith("video/")) return <Theaters {...iconProps} />;
    if (type.startsWith("audio/")) return <Audiotrack {...iconProps} />;

    switch (type) {
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Description {...iconProps} />;
      case "application/pdf":
        return <PictureAsPdf {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  // https://medium.com/@blturner3527/storing-images-in-your-database-with-base64-react-682f5f3921c2
  /*
    const selectImage = (event) =>{
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];            
            if (file) {
                const reader = new FileReader();
                reader.onload = _handlerReaderLoaded.bind(this);
                reader.readAsBinaryString(file);              
            }           
        }
    }
*/

  const selectFile = (files) => {
    if (files && files[0]) {
      setIsPreviewON(true);

      let file = files[0];
      let fileExt = file.name.split(".").pop();
      console.log(file.name + "select file type-" + fileExt);
      setSelectedFileEXT(fileExt);
      setSelectedFile(file[0]);
      if (file) {
        const reader = new FileReader();
        reader.onload = _handlerReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  };

  const _handlerReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64TextString(btoa(binaryString));
    //setUploadedFile(binaryString);
  };

  const isImage = (ext) => {
    if (ext) {
      return (
        ext === "jpg" || ext === "JPG" || ext === "JPEG" ||ext === "jpeg" || ext === "png" || ext === "PNG" || ext === "gif" || ext === "bmp" || ext === "BMP");
    }
  };

  const doUpload = (e) => {
    //e.preventDefault();
    if (isImage(selectedFileEXT)) uploadImage();
    else uploadFile();
  };

  const uploadImage = () => {
    //e.preventDefault();
    //console.log("binary string:", base64TextString);
    console.log("uploadImage selectedFileEXT :", selectedFileEXT);
    const base64 = base64TextString;
    var BlobPath = "https://directitsqlblob.blob.core.windows.net/images/";
    //var BlobPath = "https://directitsqlblob.blob.core.windows.net/ghy-test/";
    const date = new Date();
    var blobFileName = props.jobId + date.valueOf() + "." + selectedFileEXT;

    httpRequest.doUploadImage(props.accessToken, base64, blobFileName).then(function (response) {
        console.log(response.data);
      });
    httpRequest.doUploadPicture(props.accessToken, props.jobId, base64, pictureComment, BlobPath + blobFileName).then(function (response) {
        console.log(response.data);
        props.setUploadFileFlag();
        setOpenDialog(true);
        if (selectedFileEXT === "PNG" || selectedFileEXT === "png")
          setFileURL("data:image/png;base64, " + base64TextString);
        if (selectedFileEXT === "BMP" || selectedFileEXT === "bmp")
          setFileURL("data:image/bmp;base64, " + base64TextString);
        if (selectedFileEXT === "jpg" ||selectedFileEXT === "JPG" ||selectedFileEXT === "jpeg")
          setFileURL("data:image/jpeg;base64, " + base64TextString);
        if (selectedFileEXT === "gif")
          setFileURL("data:image/gif;base64, " + base64TextString);
        setFileType("image");
        setIsUploadFile(true);
      });
  };

  //https://stackoverflow.com/questions/43270793/angular-2-how-to-display-pdf-file
  const uploadFile = () => {
    var BlobPath = "https://directitsqlblob.blob.core.windows.net/order-files/";
    var blobFileName =
      props.jobId + new Date().valueOf() + "." + selectedFileEXT;
    //console.log("blobFileName is " + blobFileName);
    //console.log("loadFileName is " + selectedFile);
    httpRequest.doUploadFile(props.accessToken, base64TextString, blobFileName).then((body) => {
        console.log(JSON.stringify(body.data));
        httpRequest.doUploadPicture(props.accessToken,props.jobId,"",pictureComment,BlobPath + blobFileName).then((body) => {
            //DirectWestSvc.doUploadTripFile($scope.user.accessToken, $scope.orderId, $scope.pictureComment,BlobPath+blobFileName).then(function (data){
            console.log("uploadFile uploadFile-" + JSON.stringify(body.data));
            setOpenDialog(true);
            setIsUploadFile(true);
          });
    });
  };

  const renderHidden = (params) => {
    return (
      <Checkbox
        onClick={(e) => {
          console.log("selected row is " + JSON.stringify(params.row));
          let item = params.row;
          item.hidden = e.target.checked;
          httpRequest.setHiddenImage(props.accessToken, item).then(
            (body) => {
              if (body.data) {
                console.log("setHiddenImage- " + JSON.stringify(body.data));
                props.setUploadFileFlag();
              }
            },
            function errorCallback(err) {
              console.log("setImageHide- error:" + err);
            }
          );
        }}
        defaultChecked={params.row.hidden}
      />
    );
  };

  const renderDrNum = (params) => {
    return <a style={{ color: "#1234b0" }}>{params.value} </a>;
  };

  const renderFileType = (params) => {
    return (
      // params.row.fileType!== 'image'?
      // <Typography style = {{color: 'black'}}>{params.row.fileType}</Typography>
      // :<></>
      <Typography style={{ color: "white" }}>{params.row.fileExt}</Typography>
    );
  };

  const renderDownload = (params) => {
    return params.row.fileType !== "image" ? (
      <IconButton
        color="primary"
        onClick={() => {
          console.log("onClick");
          const link = document.createElement("a");
          link.href = params.row.url;
          link.setAttribute("download", params.row.filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }}
      >
        <DownloadIcon />
      </IconButton>
    ) : (
      <IconButton
        color="primary"
        onClick={() => {
          let fileName = params.row.url.substring(
            params.row.url.lastIndexOf("/") + 1
          );
          console.log("onClick--" + fileName);
          downloadImage(params.row.url, fileName);
        }}
      >
        <DownloadIcon />
      </IconButton>
    );
  };

  const renderCopy = (params) => {
    return (
      <IconButton
        color="primary"
        onClick={() => {
          copy(params.row.url);
        }}
      >
        <CopyIcon />
      </IconButton>
    );
  };

  const columns = [
    // {field: 'jobID', headerName: 'jobID', width: 150},
    {
      field: "download",
      headerName: "Download",
      width: 100,
      renderCell: renderDownload,
    },
    {
      field: "copy",
      headerName: "Copy url",
      width: 100,
      renderCell: renderCopy,
    },
    {
      field: "fileType",
      headerName: "Type",
      width: 90,
      renderCell: renderFileType,
    },
    {
      field: "hidden",
      headerName: "Hide",
      width: 100,
      renderCell: renderHidden,
    },
    { field: "savedTimeUTC", headerName: "DateTime", width: 150 },
    { field: "drNum", headerName: "DrNum", width: 80, renderCell: renderDrNum },
    { field: "comment", headerName: "comment", width: 150 },
  ];

  const commentChange = (event) => {
    console.log(event.target.name, event.target.value);
    setPictureComment(event.target.value);
  };

  const handleOnCellClick = (params) => {
    // console.log(params.field + "-" + params.value + '-' + params.row);
  };

  const downloadImage = (url, fileName) => {    
    saveAs(url, fileName); // Put your image url here.
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid  container  item  direction="column"   alignItems="center"   justifyContent="center"  spacing={1}
        style={{backgroundColor: "transparent", width: "100%",paddingLeft: 10, paddingBottom: 15,}}
      >
        <Grid item md={12}>
          {fileType === "image" && ( <ReactPanZoom image={fileURL} /> )}
          {(fileType === "pdf" || fileType === "doc" || fileType === "docx" || fileType === "xls" || fileType === "xlsx" ||fileType === "gif" ||fileType === "csv") && (
            //  src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://directitsqlblob.blob.core.windows.net/order-files/135805831657388729737.pdf#toolbar=0&scrollbar=0"
            <iframe src={fileURL} frameBorder="0" scrolling="auto" height="300px"  width="100%"></iframe>
          )}
          {/* {fileType === "mp4" && (
            <video            
              width="100%"
              height="300px"
              controls={true}
              >
                <source src={fileURL} type="video/mp4"></source>
                //<source src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4" type="video/mp4"></source> 
            </video>   
            //  <AzureMP
            //   src={[{src: "https://amssamples.streaming.mediaservices.windows.net/91492735-c523-432b-ba01-faba6c2206a2/AzureMediaServicesPromo.ism/manifest", type: "application/vnd.ms-sstr+xml" }]}
            //  />         
          )} */}
        </Grid>

        <Grid item md={12} className="darkDataGrid">
          {photoList.length > 0 && (
            <Box sx={{ height: 320, width: "100%" }}>
              <DataGrid
                rows={photoList}
                columns={columns}
                //columns = {isOnlyImages? columns_onlyImage:columns}
                checkboxSelection={false}
                disableColumnSelector={true}
                disableColumnMenu={true}
                onCellClick={handleOnCellClick}
                onSelectionModelChange={(ids) => {
                  try {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = photoList.filter((row) =>
                      selectedIDs.has(row.id)
                    );
                    setSelectedPhoto(selectedRowData[0]);
                    if (
                      selectedRowData[0].fileExt === "pdf" ||
                      selectedRowData[0].fileExt === "doc" ||
                      selectedRowData[0].fileExt === "docx" ||
                      selectedRowData[0].fileExt === "xls" ||
                      selectedRowData[0].fileExt === "xlsx" ||
                      selectedRowData[0].fileExt === "csv"
                    ) {
                      var file_url =
                        "https://drive.google.com/viewerng/viewer?embedded=true&url=" +
                        selectedRowData[0].url +
                        "#toolbar=0&scrollbar=0";
                      console.log(file_url);
                      setFileURL(file_url);
                    } else setFileURL(selectedRowData[0].url);
                    setFileType(selectedRowData[0].fileType);
                  } catch (e) {
                    console.log("photoList exception-" + e);
                  }
                }}
                selectionModel={selectedPhoto}
              />
            </Box>
          )}
        </Grid>
        <Grid item md={12}>
          <DropzoneArea
            style={{ height: 160, width: "100%", margin: "5px" }}
            filesLimit={1}
            showPreviewsInDropzone={isPreviewON}
            acceptedFiles={[
              "image/jpeg",
              "image/png",
              "image/bmp",
              "image/gif",
              "application/pdf",
              ".docx",
              ".xlsx",
              ".csv",
              ".wav",
              ".mp4",
              ".mp3",
              ".ogg",
              ".m4a",
            ]}
            //maxFileSize={4718592}
            maxFileSize={78643200}
            showFileNames={true}
            showFileNamesInPreview={true}
            onChange={selectFile}
            onDelete={() => {
              setFileURL("");
              setFileType("");
            }}
            getPreviewIcon={handlePreviewIcon}
            alertSnackbarProps={{
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            }}
            onAlert={(message, variant) => {
              //console.log("onAlert---" +variant + '---' + message);
              setOpenSnack(true);
              setMessageInfo(
                message.includes("too big")
                  ? "Reduce file to 75Meg or lower"
                  : message
              );
              setMessageType(variant);
            }}
            showAlerts={[]}
          />
          <TextInput
            class={"labeledField"}
            label="Comments"
            type="text"
            inputType="text"
            name="comment"
            labelSize={3}
            value={pictureComment}
            onChange={commentChange}
          />
          <Button  variant="contained"  color="primary"  style={{ marginTop: "5px" }}  onClick={doUpload}> Submit</Button>
        </Grid>
      </Grid>
      <CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        hasCloseBtn={false}
        dialogMessage={"Selected file was uploaded!"}
        dialogTitle="Notification"
        dialogAction={() => {
          setOpenDialog(false);
          setIsPreviewON(false);
        }}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={() => {setOpenSnack(false);}}
          severity={messageType}
          sx={{ width: "100%" }}
        >
          {messageInfo}
        </Alert>
      </Snackbar>
    </div>
  );
}

//https://codesandbox.io/s/rp01w?file=/src/App.tsx:1156-1285
//https://stackoverflow.com/questions/66158526/programmatically-preselecting-a-row-in-material-uis-data-gridreact

//https://stackoverflow.com/questions/17784037/how-to-display-pdf-file-in-html

//https://mui.com/material-ui/react-snackbar/
//https://javascript.plainenglish.io/material-ui-snack-bar-customization-1c0261bdc419

//sample code
//https://codesandbox.io/s/rp01w?file=/src/styles.css:545-626
