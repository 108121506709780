import React, { useState, useEffect } from "react";
import {Checkbox,Typography} from '@mui/material';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "animate.css/animate.css";

const TagCheckBox = (props) => {
  const [checkBoxValue, setCheckBoxValue] = useState(props.value);  

  const handleChange = (event) => {
    props.onChange({
      name: props.param,
      value: !checkBoxValue,
    });
    setCheckBoxValue(!checkBoxValue);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      {/* <FormControlLabel control={<Checkbox checked={checkBoxValue}/>} label={props.param} onChange={handleChange} /> */}
      <FormControlLabel
        control={<Checkbox checked={checkBoxValue}/>}
       //label={props.param}
        label={<Typography variant="body" style={{ color: 'white' }}>{props.param}</Typography>}
        sx={{
          '& .MuiFormControlLabel-label': {
            color: 'white',
          },
        }}
       onChange={handleChange}
    />
    </div>
  );
};

export default TagCheckBox;
