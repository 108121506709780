import React from "react";
import "date-fns";
import { Button,Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import HttpRequest from "../../../HttpService.js";
// import CustomDialog from "../../components/dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import styles from '../../../components/inputFields/styles.js';

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Snackbar, Alert } from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';
import { isValidDecimal, isValidInterger } from "../../../lib/Utils.js";

export default function TagAddTagDialog(props) {
 
  console.log("TagAddTagDialog============" + JSON.stringify(props.tagsList));
  const [inputs, setInputs] = React.useState({});
 
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState("");
  const [selectedTag, setSelectedTag] = React.useState(null);

  const renderTagSelect =() => {
    return(
        <TextField 
            variant="outlined"
            fullWidth            
            margin="dense"
            label="Select Tag"
            select
            defaultValue={selectedTag}
            onChange={(event) => {
               console.log(event.target.value);
               setSelectedTag(props.tagsList.find(obj => obj.tagLinkID === event.target.value))
           }}
        >
        {props.tagsList.map((option) => (
            <MenuItem key={option.tagLinkID} value={option.tagLinkID}>
                {renderTagOption(option)}
            </MenuItem>
        ))}
        </TextField>
    )
  }

  const renderTagOption = (option) =>{
    return(
       <Box style={{
           ...styles.labelWrapperNoColor,
           borderColor: option.tagColor || '#007E82',
           backgroundColor: option.tagColor || '#13ACB1',
           height: 30,        
           width: 200    
        }}>
        {option.icon_url && <img 
          src={option.icon_url}
          alt={option.label}
          style={{height: '1.0em',}}
        />}
        <Typography variant = 'subtitle1' style={{
          ...styles.labelNoColor,
          marginLeft:10,
          color: option.tagTextColor
        }}>{option.label}</Typography>
        </Box>
    )    
  }
  
  const renderElements = (item, index) => {
    const setTagsValue =(key,value) =>{
        let tag = selectedTag;       
        tag.params.forEach((param) =>{
            if(param.param === key){
                param.value = value;
            }
        });
        setSelectedTag(tag);       
    }
    const params = item;    
    console.log("TagAddTagDialog renderElements --------" + JSON.stringify(item));
    if (params.type === "integer") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          placeholder="0"
          fullWidth
          margin="dense"
          type="number"
          key={params.param}
          label={params.param}
          defaultValue={params.default}
          onChange={(input) => {
            if(isValidInterger(Number(input.target.value))){
              setInputs((prevState) => {
                return {
                  ...prevState,
                  [params.param]: input.target.value,
                };
              }); 
              setTagsValue(params.param,input.target.value); 
            }  
          }}
          onBlur={(input) => {
            if(!isValidInterger(Number(input.target.value))){
              input.target.value=''
            }  
          }}
        />
        </Tooltip>
      );
    } else if (params.type.includes("varchar")) {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          key={params.param}
          margin="dense"
          label={params.param}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.param]: input.target.value,
              };
            }); 
            setTagsValue(params.param,input.target.value);             
          }}
        />
        </Tooltip>
      );
    } else if (params.type === "memo") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          multiline
          minRows={3}
          fullWidth
          key={params.param}
          margin="dense"
          label={params.param}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.param]: input.target.value,
              };
            }); 
            setTagsValue(params.param,input.target.value);             
          }}
        />
        </Tooltip>
      );
    } else if (params.type === "dropdown") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          variant="outlined"
          fullWidth
          key={params.param}
          margin="dense"
          label={params.param}
          select
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.param]: input.target.value,
              };
            }); 
            setTagsValue(params.param,input.target.value);              
          }}
        >
          {params.list.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        </Tooltip>
      );
    } else if (params.type === "datetime") {
      return (      
        <Tooltip title={params.mo} placement="top-end" arrow>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField autoFocus={index === 0 ? true : false} {...props} />
            )}
            label={params.param}
            ampm={false}
            onChange={(input) => {
              setInputs((prevState) => {
                return {
                  ...prevState,
                  [params.param]: input,
                };
              }); 
              setTagsValue(params.param,input);                
            }}
          />
        </LocalizationProvider>
        </Tooltip>
      );
    } else if (params.type === "decimal") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          type="number"
          placeholder="0.0"
          fullWidth
          key={params.param}
          margin="dense"
          label={params.param}
          defaultValue={params.default}
          onChange={(input) => {
            if(isValidDecimal(input.target.value)){
              setInputs((prevState) => {
                return {
                  ...prevState,
                  [params.param]: input.target.value,
                };
              }); 
              setTagsValue(params.param,input.target.value);  
            }           
          }}
          onBlur={(input) => {
            if(!isValidDecimal(input.target.value)){
              input.target.value=''
            }  
          }}
        ></TextField>
        </Tooltip>
      );
    } else if (params.type === "checkbox") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                label={params.param}
                defaultChecked={params.default}
                //defaultChecked ={true}
                color="primary"
                onChange={(event) => {
                  setInputs((prevState) => {
                    return {
                      ...prevState,
                      [params.param]: event.target.checked,
                    };
                  }); 
                  setTagsValue(params.param,event.target.checked);                  
                }}
              />
            }
            label={
              <Typography style={{ color: "black" }}>
                {params.param}
              </Typography>
            }
          />
        </FormControl>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        className="darkDialog"
        open={props.open}
        //onClose={() => props.handleDialogClose()}
      >
        <DialogTitle>Add Tag</DialogTitle>
        <DialogContent>
          {renderTagSelect()}
          {selectedTag && selectedTag.params && selectedTag.params.map((item, index) => renderElements(item, index))} 
        </DialogContent>
        <DialogActions>
            <Button
              onClick={() => {
                // console.log("TagAddTagDialog====selected tag========" + JSON.stringify(selectedTag.tagLinkID));             
                //console.log("TagAddTagDialog====input========" + JSON.stringify(inputs));
                //console.log("TagAddTagDialog====tag.params========" + JSON.stringify(selectedTag.params));   
                let postTag ={}; 
                postTag.checked = "1";
                selectedTag.params?.forEach((item) =>{             
                    postTag[item.param]=item.value;
                });      
                const content = {
                    tagLinkID: selectedTag.tagLinkID,
                    varJSON:  JSON.stringify(postTag)
                    //varJSON:  selectedTag.params?JSON.stringify(postTag):""
                }
                HttpRequest.postTripAddedTag(props.accessToken,props.jobId,content).then((body) =>{
                    console.log("postTripAddedTag " + JSON.stringify(body.data));
                    setSelectedTag(null)
                    props.handleDialogClose();
                });
                
            }}
          >
            <CheckIcon></CheckIcon> Confirm
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setInputs({});
              setSelectedTag(null)
              props.handleDialogClose();
            }}
          >
            <ClearIcon></ClearIcon> Cancel
          </Button>
        </DialogActions>
      </Dialog>   
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="info"
          sx={{ width: "100%" }}
        >
          {messageInfo}
        </Alert>
      </Snackbar>
    </div>
  );
}
  