import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { Checkbox, Box, Tooltip, IconButton } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import styles from '../../../components/inputFields/styles.js';
import TagDropdown from "./TagDropdown";
import TagTextFieldsEditable from "./TagTextFields.js";
import TagNumberFiels from "./TagNumberFields.js";
import TagDecimalField from "./TagDecimalFields.js";
import TagDatetimeField from "./TagDatetimeField.js";
import TagCheckBox from "./TagCheckBox.js";
import TagDatePicker from "./TagDatePicker.js";
import TagTimePicker from "./TagTimePicker.js";
import TagMemo from "./TagMemoFields.js";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";

const TagContainer = (props) => {
  const [checked, setChecked] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);

  useEffect(() => {
    if (props.info && props.info.active !== checked) {
      setChecked(props.info.active);
      props.onCheckedChange(props.info.active);
    }
  }, [props.info, checked, props.onCheckedChange]);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    props.onCheckedChange(event.target.checked);
    setValueChanged(true);
  };

  const handleChange = (val) => {
    setValueChanged(true);
    props.onChange(val);
  };

  const renderTagField = (item) => {
    if (!item) return null;
    
    switch(item.type) {
      case "integer":
        return (
          <TagNumberFiels
            key={item.param}
            label={item.prompt}            
            param={item.param}
            variant="outlined"
            minNum={item.min}
            maxNum={item.max}
            prompt={item.mo}
            required={item.required}
            onChange={handleChange}
            info={props.element}
            value={item.value}
          />
        );
      case "varchar":
        return (
          <TagTextFieldsEditable 
            key={item.param}
            label={item.prompt}
            param={item.param}
            variant={props.variant}         
            prompt={item.mo}
            maxLength={item.length}          
            required={item.required}          
            onChange={handleChange}
            value={item.value}
          />
        );
      case "decimal":
        return (
          <TagDecimalField
            key={item.param}
            label={item.prompt}
            param={item.param}
            variant={props.variant}         
            prompt={item.mo}
            minNum={item.min}
            maxNum={item.max}      
            required={item.required}         
            onChange={handleChange}
            value={item.value}
          />
        );
      case "datetime":
        return (
          <TagDatetimeField
            key={item.param}
            label={item.prompt}
            param={item.param}
            variant={props.variant}         
            prompt={item.mo}         
            required={item.required}         
            onChange={handleChange}
            value={item.value}
          />
        );
      case "date":
        return (
          <TagDatePicker
            key={item.param}
            label={item.prompt}
            param={item.param}
            variant={props.variant}         
            prompt={item.mo}         
            required={item.required}         
            onChange={handleChange}
            value={item.value}
          />
        );
      case "time":
        return (
          <TagTimePicker
            key={item.param}
            label={item.prompt}
            param={item.param}
            variant={props.variant}         
            prompt={item.mo}          
            required={item.required}        
            onChange={handleChange}
            value={item.value}
          />
        );
      case "dropdown":
        return (
          <TagDropdown
            key={item.param}
            label={item.prompt}
            param={item.param}
            variant={props.variant}         
            prompt={item.mo}
            maxLength={item.length}  
            required={item.required} 
            onChange={handleChange}
            choices={item.choices}
            value={item.value}
          />
        );
      case "checkbox":
        return (
          <TagCheckBox
            key={item.param}
            label={item.prompt}
            param={item.param}
            variant={props.variant}         
            prompt={item.mo}
            required={item.required}         
            onChange={handleChange}
            value={item.value}
          />
        );
      case "memo":
        return (
          <TagMemo
            key={item.param}
            label={item.prompt}            
            param={item.param}
            variant={props.variant}         
            prompt={item.mo}
            maxLength={item.length} 
            required={item.required}         
            onChange={handleChange}
            value={item.value}
          />
        );
      default:
        return null;
    }
  };

  if (!props.info) return null;

  const restrictions = Array.isArray(props.restrictions) ? props.restrictions : [];

  return (
    <Grid container direction="row" style={{ marginBottom: 20 }} display="inline-grid" spacing={1}>
      <Grid item xs={3}>
        <Grid container direction="row">
          <Grid item xs={1}>{valueChanged?<IconButton sx={{ color: "#56D9DD" }}    className="btnPrimary" onClick={()=>{setValueChanged(false); props.onSave()}}><SaveIcon /></IconButton>:<></>}</Grid>
          {props.info.helpText!==null &&props.info.helpText!==''?<Grid item xs={1}><Tooltip title={props.info.helpText}><IconButton className="btnPrimary" onClick={()=>{}} disabled={false}><InfoIcon /></IconButton></Tooltip></Grid>:<></>}
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <Checkbox sx={{ color: "#007E82", '&.Mui-checked': {color: "#56D9DD",}}}   
              checked={checked}
              onChange={handleCheck}
            />
          </Grid>
          <Grid item xs={5} xl={7}>
             <Box style={{
              ...styles.labelWrapperNoColor,
              borderColor: props.info.tagColor || '#007E82',
              backgroundColor: props.info.tagColor || '#13ACB1',
              height: 42             
            }}>
              {props.info.icon_url && <img 
                src={props.info.icon_url}
                alt={props.label}
                style={{height: '1.5em',}}
              />}
              <Typography variant = 'subtitle1' style={{
                ...styles.labelNoColor,
                marginLeft:10,
                color: props.info.tagTextColor
              }}>{props.label}</Typography>
              </Box>
          </Grid>
        </Grid>          
      </Grid>      
      <Grid item xs={5} xl={9}>
        <Grid container spacing={0}>
        {/* {checked && props.restrictions.map((item) => renderTagField(item))} */}
           {props.restrictions.map((item) => renderTagField(item))}
        </Grid>
      </Grid>  
    </Grid>
  );
};

export default TagContainer;
