import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Typography, 
  Divider, 
  Tooltip, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Box,
  InputAdornment,
  TextField,
  MenuItem,
  Button
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import InfoField from "./infoField";
import CallerField from "./callerField";
import TextInput from '../../components/inputFields/TextInput';
import httpRequest from '../../HttpService';
import accessToken from '../../HttpService';
import styles from '../../components/inputFields/styles';
import InputField from './inputField';

export default function OrderDetails(props) {
  const {
    order = {},
    isEdit = false,
    currentAccount = {},
    accountList = [],
    callerList = [],
    isLattice = false,
    TripServiceAccountNotes = "",
    renderDropdown,
    renderFieldWithInputAdornment,
    renderEditFieldWithInputAdornment,
    handleValueChange,
    handlePiecesChange,
    handleMaxLengthFiveChange,
    jobDetailLabelStyle,
    packageList = [],
    rateChoices = [],
    currentRate = {},
    setCurrentRate,
    tagList = []
  } = props;

  const [tripServiceNoteList, setTripServiceNoteList] = useState([]);

  console.log("OrderDetails props:", props);

  useEffect(() => {
    console.log("OrderDetails useEffect - accountList:", accountList);
    console.log("OrderDetails useEffect - currentAccount:", currentAccount);
  }, [accountList, currentAccount]);

  useEffect(() => {
    if (props.jobId) {
      getTripServiceNotes(props.jobId);
    }
  }, [props.jobId]);

  const getTripServiceNotes = (jobId) => {
    httpRequest.getTripServiceNotes(accessToken, jobId).then((body) => {
      try {
        console.log("getTripServiceNotes", JSON.stringify(body.data));
        let tripServiceNoteList = [];
        body.data.forEach((row) => {
          if (row.notes && !row.source?.toLowerCase().includes("account")) {
            tripServiceNoteList.push(row);
          }
        });
        setTripServiceNoteList(tripServiceNoteList);
      } catch (e) {
        console.log("getTripServiceNotes exception", e);
      }
    });
  };

  const renderAccountField = () => {
    if (!isEdit) {
      return <InfoField value={currentAccount?.AccountName || order?.ACCOUNT || 'N/A'} />;
    }
    
    if (Array.isArray(accountList) && accountList.length > 0) {
      return renderDropdown(
        "account", 
        currentAccount?.account, 
        accountList, 
        "account", 
        "AccountName", 
        '100%'
      );
    }
    
    return <InfoField value={currentAccount?.AccountName || order?.ACCOUNT || 'N/A'} />;
  };

  const renderLabel = (text) => (
    <Typography variant="button" color="primary" style={jobDetailLabelStyle}>
      {text}
    </Typography>
  );

  const renderMultilineText = (name, val, backgroundColor, border, textColor) => {
    return (
      <TextInput
        inputType="text"
        name={name}
        multiline
        minRows={1}
        hideLabel={true}
        value={val}
        disabled
        style={{
          backgroundColor: backgroundColor,
          borderRadius: "4px",
          color: textColor ? textColor : "#fff",
          border: border,
        }}
      />
    );
  };

  const renderTag = (tag) => {
    return (
      <Grid item xs={12} container space={12} direction="row" 
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>        
        <Grid item xs={2} className='sidebar-tags'>
          <Box style={{ ...styles.labelWrapperNoColor, backgroundColor: tag.tagColor || 'white' }}>
            {tag.icon_url && 
              (tag.tagColor ? 
                <img src={tag.icon_url} style={{height: '2.6em', width:'2.6em'}} alt="Tag icon"/> :
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '2.6em',
                  height: '2.6em',
                }}>
                  <img src={tag.icon_url} style={{ width:'2.4em' }} alt="Tag icon"/>
                </div>
              )
            }
          </Box>
        </Grid>
        <Grid item xs={9} xl={10}>
          {renderLabel(tag.clientLabel)}
          {renderMultilineText(tag.clientLabel, tag.variableDisplayValue !== null ? tag.variableDisplayValue : tag.clientLabel, "", "", tag.variableTextColor)}
        </Grid> 
      </Grid>  
    )
  };

  const renderStatusString = (statusString) => {
    const driverMatch = statusString?.match(/Driver (\d+)/);
    if (driverMatch) {
      const [fullMatch, driverNumber] = driverMatch;
      const parts = statusString.split(fullMatch);
      return (
        <Typography variant="button" color="textPrimary" style={{ fontSize: 22, fontWeight: 550, marginLeft: 10 }}>
          {parts[0]}
          <Tooltip title="View Driver Details">
            <Link
              to={`/driver/${driverNumber}`}
              style={{
                color: '#3BA264',
                textDecoration: 'underline',
                display: 'inline-flex',
                alignItems: 'center'
              }}
            >
              {fullMatch}
            </Link>
          </Tooltip>
          {parts[1]}
        </Typography>
      );
    }
    return (
      <Typography variant="button" color="textPrimary" style={{ fontSize: 22, fontWeight: 550, marginLeft: 10 }}>
        {statusString}
      </Typography>
    );
  };

  return (
    <Grid item container direction="column" justifyContent="flex-start" alignItems="stretch"
      xs={5} lg={3} 
      style={{ 
        margin: "0", 
        width: "100%", 
        height: "100%",
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: "16px", 
        boxSizing: 'border-box'
      }} 
      className="darkForm editJobForm">
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        {renderLabel("Account")}
        {renderAccountField()}
      </Grid>
  
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        {renderLabel("Account Info")}
        {renderMultilineText("account info", TripServiceAccountNotes, "#F78133", "1px solid #F78133")}
      </Grid>
  
      <Grid item container xs={12} style={{ marginBottom: "16px" }}>
        <Grid item xs={6} style={{ paddingRight: "8px" }}>
          {renderLabel("Caller")}
          {!isEdit && (<InfoField value={order?.CALLER} />)}
          {isEdit && (
            <CallerField
              caller={order?.CALLER}
              callers={callerList}
              account={order?.ACCOUNT}
              removeCallerFromList={props.removeCallerFromList}
              refreshCallList={props.getUserEmails}
              handleValueChange={handleValueChange}
            />
          )}
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: "8px" }}>
          {!isLattice ? (
            <>
              {renderLabel("Notification Email")}
              {!isEdit && (<InfoField value={order?.emailConf} />)}
              {isEdit && (
                <InputField
                  inputType="text"
                  name="emailConf"
                  defaultValue={order?.emailConf}
                  onBlur={handleValueChange}
                />
              )}
            </>
          ) : (
            <>
              {renderLabel("ID User")}
              <InfoField value={order?.ID_USER} />
            </>
          )}
        </Grid>
      </Grid>
  
      {!isLattice && (
        <>
          <Grid item container xs={12} style={{ marginBottom: "16px" }}>
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
              {renderLabel("Package Type")}
              {!isEdit && (<InfoField name="package type" value={order?.DESC}/>)}
              {isEdit && (renderDropdown("Desc", order.DESC, packageList, "description","description", '100%'))}
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              {renderLabel("Value")}
              {!isEdit && renderFieldWithInputAdornment(order?.DECLARED || 0, "$", "start")}
              {isEdit && renderEditFieldWithInputAdornment("declared", "number", order?.DECLARED || 0, "$", "start", handleMaxLengthFiveChange, handleValueChange)}
            </Grid>
          </Grid>
  
          <Grid item container xs={12} style={{ marginBottom: "16px" }}>
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
              {renderLabel("Delivery Service")}
              {!isEdit && (<InfoField value={`${order?.SER_AREA} - ${order?.SER_TIME} [ ${order?.RATE} ]`} />)}
              {isEdit && (
                <TextField
                  name="service"
                  variant="outlined"
                  select
                  size="small"
                  className="editableJobField"
                  value={currentRate?.service}
                  onChange={(event) => {
                    let item = rateChoices.find((o) => o.service === event.target.value);
                    setCurrentRate(item);
                    handleValueChange({ target: { name: "RATE", value: item } });
                  }}
                  style={{ width: '100%' }}
                >
                  {rateChoices.map((item) => (
                    <MenuItem key={item.id} value={item.service}>{item.descr}</MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              {renderLabel("Billing Reference")}
              {!isEdit && (<InfoField value={order?.CUST_REF} />)}
              {isEdit && (
                <InputField
                  inputType="text"
                  name="CUST_REF"
                  defaultValue={order?.CUST_REF}
                  onBlur={handleValueChange}
                />
              )}
            </Grid>
          </Grid>
  
          <Grid item container xs={12} style={{ marginBottom: "16px" }}>
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
              {renderLabel("Pieces")}
              {!isEdit && (renderFieldWithInputAdornment(order?.Pieces, "#", "start"))}
              {isEdit && (renderEditFieldWithInputAdornment("pieces", "number", order?.Pieces, "#", "start", handlePiecesChange, handleValueChange))}
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              {renderLabel("Weight")}
              {!isEdit && (renderFieldWithInputAdornment(order?.weight, "lbs", "end"))}
              {isEdit && (renderEditFieldWithInputAdornment("weight", "number", order?.weight, "lbs", "end", handleMaxLengthFiveChange, handleValueChange))}
            </Grid>
          </Grid>
  
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            {renderLabel("Instructions")}
            {!isEdit && renderMultilineText("notes", order?.DEL_INST, "#DE3562", "1px solid #DE3562")}
            {isEdit && (
              <InputField
                inputType="text"
                name="del_inst"
                inputLength={200}
                defaultValue={order?.DEL_INST}
                onChange={(event) => {
                  const value = event.target.value;
                  event.target.value = value.replace(/[^a-zA-Z0-9-?+$%@&<>|#?,.!<>()/*'\\;: ]/g, "");
                }}
                onBlur={handleValueChange}
              />
            )}
          </Grid>
        </>
      )}
  
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Divider style={{ backgroundColor: "#4e9182", height: 3 }} />
      </Grid>
  
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        {order?.StatusString && renderStatusString(order.StatusString)}
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Typography variant="button" color="textPrimary" style={{ fontSize: 22, fontWeight: 550, marginLeft: 10 }}> {order?.locationString} </Typography>
      </Grid>
  
      {(order?.dtDelivered_display || order?.PROOF_DEL) && (
        <Grid item container xs={12} style={{ marginBottom: "16px" }}>
          {order?.dtDelivered_display && (
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
              {renderLabel("Delivered Time")}
              <InfoField name="delivered time" value={order.dtDelivered_display}/>
            </Grid>
          )}
          {order?.PROOF_DEL && (
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              {renderLabel("Proof of Delivery")}
              <InfoField name="proof of delivery" value={order.PROOF_DEL}/>
            </Grid>
          )}
        </Grid>
      )}
  
      {order?.signatureURL && (
        <Grid item container xs={12} spacing={2} style={{ marginBottom: "16px" }}>
          <Grid item xs={6}>{renderLabel("Signature")}</Grid>
          <Grid item xs={6}>
            <img src={order.signatureURL} alt="Signature" style={{ width: "190px" }}/>
          </Grid>
        </Grid>
      )}
      
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Divider style={{ backgroundColor: "#4e9182", height: 3 }} />
      </Grid>
  
      {isLattice && (
        <>
          <Grid item container xs={12} style={{ marginBottom: "16px" }}>
            <Grid item xs={2}>
              <div style={{ padding: "10px" }}>
                <img src={order?.descriptionIconURL} style={{ width: "100%", height: "100%",minHeight:'50px', minWidth:'50px', display: "flex", borderRadius: "8px" }} alt="Description Icon"/>
              </div>
            </Grid>
            <Grid item xs={10} style={{ paddingLeft: "8px" }}>
              {renderLabel("Description/Additional Description")}
              {!isEdit && (<InfoField name="package type"  value={order?.DESC}/>)}
              {isEdit && (renderDropdown("Desc", order.DESC, packageList, "description","description", '100%'))}               
           </Grid>
         </Grid>
         <Grid item container xs={12} style={{ marginBottom: "16px" }}>
           <Grid item xs={4} style={{ paddingRight: "8px" }}>
             {renderLabel("Pieces")}
             {!isEdit && (renderFieldWithInputAdornment(order?.Pieces, "#", "start"))}
             {isEdit && (renderEditFieldWithInputAdornment("pieces", "number", order?.Pieces, "#", "start", handlePiecesChange, handleValueChange))}
           </Grid>
           <Grid item xs={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
             {renderLabel("Weight")}
             {!isEdit && (renderFieldWithInputAdornment(order?.weight, "lbs", "end"))}
             {isEdit && (renderEditFieldWithInputAdornment("weight", "number", order?.weight, "lbs", "end", handleMaxLengthFiveChange, handleValueChange))}
           </Grid>
           <Grid item xs={4} style={{ paddingLeft: "8px" }}>
             {renderLabel("Rate")}
             <InfoField value={
               currentRate?.Rate 
                 ? `$${parseFloat(currentRate.Rate).toFixed(2)}` 
                 : 'N/A'
             } />
           </Grid>
         </Grid>
         <Grid item xs={12} style={{ marginBottom: "16px" }}>
           <Accordion>
             <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="Additional Info"
               id="Additional Info"
             >
               <Typography>Additional Info</Typography>
             </AccordionSummary>
             <AccordionDetails>
               <Grid container spacing={2}>
                 <Grid item xs={6}>
                   {renderLabel("serviceMinutes")}                
                   {renderFieldWithInputAdornment(order?.serviceMinutes, "min", "end")}
                 </Grid>
                 <Grid item xs={6}>
                   {renderLabel("serviceDays")}                
                   {renderFieldWithInputAdornment(order?.serviceDays, "days", "end")}
                 </Grid>
                 <Grid item xs={6}>
                   {renderLabel("billMinutes")}                
                   {renderFieldWithInputAdornment(order?.billMinutes, "min", "end")}
                 </Grid>
                 <Grid item xs={6}>
                   {renderLabel("drivDist")}                
                   {renderFieldWithInputAdornment(order?.drivDist, "km", "end")}
                 </Grid>
               </Grid>
             </AccordionDetails>
           </Accordion>
         </Grid>
       </>
     )}
     {isLattice && tagList && tagList.length > 0 && tagList.map((tag, index) => (
       <React.Fragment key={index}>
         {renderTag(tag)}
       </React.Fragment>
     ))}
   </Grid>
 );
}