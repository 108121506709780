import React from 'react';
import { useState, useEffect, useCallback, useMemo, useRef  } from 'react';
import { useParams, useHistory, Link   } from 'react-router-dom';
import Copyright from "../Copyright";
import Typography from "@material-ui/core/Typography";
import httpRequest from "../HttpService";
import AddressCard from "./components/addressCard";
import CameraButton from "./components/cameraButton";
import TextInput from "../components/inputFields/TextInput";
import Gallery from "./components/gallery";
import LatticeStatusBar from './components/LatticeStatusBar';
import moment from "moment";
import InfoField from "./components/infoField";
import logo from "./images/logo_west-direct.jpg";
// import CustomIconButton from "../components/inputFields/CustomIconButton";
import {Grid, Button, ButtonGroup, InputAdornment, Box,Collapse} from "@material-ui/core";
import { Divider,Tooltip} from '@mui/material';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import "../components/sideIcon/sideIcon.css";
import "../jobDetail/jobDetail.css";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TripFunctionDialog from "./components/tripFunctionDialog";
import TripDriverList from "./components/tripDriverList";
import TripHistoryList from "./components/tripHistoryList";
import TripRatingList from "./components/tripRatingList";
import TripPiecesList from './components/TripPiecesList.js';
import TagsView from './components/TagsView';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputField from "./components/inputField";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker,MuiPickersUtilsProvider,} from "@material-ui/pickers";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import CustomDialog from "./components/dialog";
import CallerField from "./components/callerField";
import moment_tz from "moment-timezone";
import NoticeEmitter from "../NoticeUtil";
import { Snackbar, Alert } from "@mui/material";
import styles  from '../components/inputFields/styles.js';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StreetViewMap from "../components/streetViewSideBySideMap";


import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Drawer as DxDrawer } from 'devextreme-react/drawer';
import { List as DxList } from 'devextreme-react/list';
import { Toolbar as DxToolbar, Item as DxItem } from 'devextreme-react/toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Tabs } from 'devextreme-react/tabs';
import { makeStyles } from '@material-ui/core/styles';

import { Popover } from '@material-ui/core';

import OrderSteps from './components/OrderSteps.js';
import MapView from './components/MapView.js';
import OrderDetails from './components/OrderDetails.js';
import SideMenu from './components/SideMenu';
import DialogManager from './components/DialogManager';
import SnackbarManager from './components/SnackbarManager';


//DevExpress CSS
import 'devextreme/dist/css/dx.light.css';

const viewMappings = {
  0: 'map',
  1: 'pictures',
  2: 'history',
  3: 'drivers',
  4: 'tags',
  5: 'Pieces',
};

const reverseViewMappings = Object.fromEntries(
  Object.entries(viewMappings).map(([key, value]) => [value, parseInt(key)])
);

const PicturesTab = ({ viewAddress, addr, order, photoArray, isStreetViewExpanded, setIsStreetViewExpanded, handleGPSChange, toggleGallery, galleryVisible, accessToken, jobId, setIsUploadFile, classes }) => {
  const handleAccordionChange = (event, isExpanded) => {
    setIsStreetViewExpanded(isExpanded);
  };

  return (
    <>
      <CameraButton images={photoArray} onChange={toggleGallery} />
      {galleryVisible && photoArray && (
        <div className="containerGallery" style={classes.gallery}>
          <Gallery accessToken={accessToken} jobId={jobId} photoArray={photoArray} setUploadFileFlag={setIsUploadFile} />
        </div>
      )}
      <Accordion 
        expanded={isStreetViewExpanded}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="street-view-content"
          id="street-view-header"
        >
          <Typography>Street View</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isStreetViewExpanded && viewAddress.lat !== "" && viewAddress.long !== "" ? (
            <div style={{ height: '600px', width: '100%' }}>
              <StreetViewMap
                address={viewAddress.companyName}
                lat={viewAddress.lat}
                lng={viewAddress.long}
                type={addr}
                sLat={order.sLat}
                sLong={order.sLong}
                cLat={order.cLat}
                cLong={order.cLong}
                images={photoArray}
                orderStatus={order.Status}
                dtDelivered={order.dtDelivered}
                delLat={order.delLat}
                delLong={order.delLong}
                handleGPSChange={handleGPSChange}
              />
            </div>
          ) : (
            <Typography>
              {isStreetViewExpanded 
                ? "No street view available for this address."
                : "Expand to load street view."}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default function LatticeJobDetail(props) { 

  const reverseViewMappings = useMemo(() => 
    Object.fromEntries(Object.entries(viewMappings).map(([key, value]) => [value, parseInt(key)]))
  , []);


    const classes = {
        root: {
          backgroundColor: "transparent",
        },
        gallery: {   
        },
        drawerList: {
          width: 360,
          padding: 0,
        },
        drawerWrapper: {
          backgroundColor: "transparent",
          top: "64px",
          left: "38px",
        },
        drawerHeader: {
          display: "flex",
          alignItems: "center",
          padding: 1,
          justifyContent: "space-between",
        },
        toolbarGridItem: {
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "15px",
        },
        jobDetailLabel: {
          fontSize: 12,
          fontWeight: 500,
        },
      }    
    
      const accessToken = localStorage.getItem("access_token");
      const { jobId, view: urlView } = useParams();
      const history = useHistory();
      const [companyLogo,setCompanyLog] = useState(logo);
      const [galleryVisible,setGalleryVisible] = useState(false);
      const [photoArray,setPhotoArray] = useState([]);      
      const [view,setView] = useState("map");  //street
      const [addr,setAddr] = useState("delivery");  //street
      const [GPSUpdateButtonText,setGPSUpdateButtonText] = useState("Update Delivery GPS");  //street
      const [isMenuOpen,setIsMenuOpen] = useState(false);
      const [viewAddress,setViewAddress] = useState({
        companyName: "",
        lat: "",
        long: "",
        address: "",
      });
      
      const [viewFunctions,setViewFunctions] = useState([]);
      const [dispatchFunctions,setDispatchFunctions] = useState([]);
      const [serviceFunctions,setServiceFunctions] = useState([]);
      const [ratingFunctions,setRatingFunctions] = useState([]);
      const [accountList, setAccountList] = useState([]);
      const [TripServiceNoteList,setTripServiceNoteList] = useState([]);     
      const [callerList,setCallerList] = useState([]);    
      const [steps,setSteps] = useState([]);
      const [isUploadFile,setIsUploadFile] = useState(false);
      const [isEdit,setIsEdit] = useState(false);
      const [dialogIsOpen, setDialogIsOpen] = useState(false);
      const [openUpdatingDialog,setOpenUpdatingDialog] = useState(false);
      const [openSnack,setOpenSnack] = useState(false);
      const [updatingItmeStr,setUpdatingItmeStr] = useState("");  
      const [messageInfo,setMessageInfo] = useState("");  
      const [TripServiceAccountNotes,setTripServiceAccountNotes ] = useState("");  
      const [gpsData,setGpsData] = useState({});
      const [currentAccount,setCurrentAccount] = useState({});
      const [currentRate,setCurrentRate] = useState({});
      const [updatingReferences,setUpdatingReferences] = useState({});
      const [updatingItem,setUpdatingItem] = useState({});
      const [tripFunction, setTripFunction] = useState({ parameters: [] });
      const [latestUpdatedPostion,setLatestUpdatedPostion] = useState(null);
      const [updateGPSSource,setUpdateGPSSource] = useState(null);
      const [interval,setInterval] = useState(null);
      const [order,setOrder] = useState({});
      const [orderDTCallIn,setOrderDTCallIn]=useState(null);
      const [orderDTDCT,setOrderDTDCT]=useState(null);
      const [orderSCT,setOrderSCT]=useState(null);
      const [dtPickupBy,setDTPickupBy]=useState(null);
      const [dtDontDeliveryBefore,setDTDontDeliveryBefore]=useState(null);
      const [adjustRate,setAdjustRate]= useState(null);
      const [locationUpdateDialog,setLocationUpdateDialog] = useState(false);  
      const [isLattice,setIsLattice] = useState(false);  
      const [tagList,setTagList] = useState([]); 
      const [isDrawerOpen, setIsDrawerOpen] = useState(true);

      const [referenceList,setReferenceList] = useState([]);
      const [packageList,setPackageList] = useState([]);
      const [selectedMenuItem, setSelectedMenuItem] = useState('View');
      const [showMapView, setShowMapView] = useState(true);      
      const [category,setCategory] = useState("View"); 
      const [rateChoices,setRateChoices] = useState([]);
      const [selectedTab, setSelectedTab] = useState(0); // Default to map view

      const [expanded, setExpanded] = useState(false);
      const [isStreetViewExpanded, setIsStreetViewExpanded] = useState(false);

      const hoverTimeoutRef = useRef(null);


      useEffect(() => {
        return () => {
          if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
          }
        };
      }, []);

      const handleChange = (event, isExpanded) => {
        console.log("Accordion clicked, isExpanded:", isExpanded);
        setExpanded(isExpanded);
      };
      

      const toggleDrawer = () => {
        setIsDrawerOpen(prev => !prev);
      };
    
      const navigation = [
        { 
          id: 'Dispatch', 
          text: 'Dispatch', 
          icon: 'fa fa-truck',
          options: dispatchFunctions
        },
        { 
          id: 'Service', 
          text: 'Service', 
          icon: 'fa fa-wrench',
          options: serviceFunctions
        },
        { 
          id: 'Rating', 
          text: 'Rating', 
          icon: 'fa fa-sliders',
          options: ratingFunctions
        }
      ];

      const viewTabs = [
        { id: 0, text: 'Map' },
        { id: 1, text: 'Pictures' },
        { id: 2, text: 'History' },
        { id: 3, text: 'Drivers & Rating' },
        { id: 4, text: 'Tags' },
        { id: 5, text: 'Pieces' }
      ];

      const handleTabClick = (e) => {
        setSelectedTab(e.itemData.id);
        updateView(e.itemData.id);
      };

  const [pageTitle, setPageTitle] = useState('DirectIt INX');     
      

  const getInitialTab = () => {
    const savedView = localStorage.getItem(`jobDetailView_${jobId}`);
    if (urlView && reverseViewMappings.hasOwnProperty(urlView)) {
      return reverseViewMappings[urlView];
    } else if (savedView && viewMappings.hasOwnProperty(savedView)) {
      return parseInt(savedView);
    } else {
      return 0; // default to map view
    }
  };

      const [showContent, setShowContent] = useState(() => {
        const savedView = localStorage.getItem(`jobDetailView_${jobId}`);
        if (urlView) {
          return reverseViewMappings[urlView] || 0;
        } else if (savedView) {
          return parseInt(savedView);
        } else {
          return 0; // default to map view
        }
      }); // 0-map 1-pic 2-history 3-driver&rating
      
      const updateView = useCallback((newView) => {
        if (viewMappings.hasOwnProperty(newView)) {
          setSelectedTab(newView);
          localStorage.setItem(`jobDetailView_${jobId}`, newView.toString());
          history.push(`/jobDetail/${jobId}/${viewMappings[newView]}`);
        } else {
          console.error(`Invalid view: ${newView}`);
          setSelectedTab(0);
          localStorage.setItem(`jobDetailView_${jobId}`, '0');
          history.push(`/jobDetail/${jobId}/map`);
        }
      }, [jobId, history]);


      useEffect(() => {
        const savedView = localStorage.getItem(`jobDetailView_${jobId}`);
        let initialView;
        let token = localStorage.getItem("access_token");
        if (token === undefined || token === null) window.location.href = "/";    
        
        // Update the document title with a delay
        const titleTimer = setTimeout(() => {
            if (jobId) {
                const shortId = jobId.slice(-5);  // Get last 5 characters
                document.title = `${shortId} - ${viewMappings[showContent]}`;
            }
        }, 100);
    
        loadTripDetails(jobId);
        getTripFunctions(jobId);
   
        NoticeEmitter.addListener("refreshJobDetail", (data) => {
            console.log("==NoticeEmitter refreshJobDetail==" + data);
            loadTripDetails(jobId);         
        });
    
        // Clear saved views for other jobs
        Object.keys(localStorage).forEach(key => {
          if (key.startsWith('jobDetailView_') && !key.endsWith(jobId)) {
            localStorage.removeItem(key);
          }
        });
    
        if (urlView && reverseViewMappings.hasOwnProperty(urlView)) {
          initialView = reverseViewMappings[urlView];
        } else if (savedView && viewMappings.hasOwnProperty(savedView)) {
          initialView = parseInt(savedView);
        } else {
          initialView = 0; // default to map view
        }
    
        if (initialView !== selectedTab) {
          updateView(initialView);
        }
    
        // Clear saved views for other jobs
        Object.keys(localStorage).forEach(key => {
          if (key.startsWith('jobDetailView_') && !key.endsWith(jobId)) {
            localStorage.removeItem(key);
          }
        });
    
      }, [jobId, urlView, selectedTab, updateView]);

    
    const updateDocumentTitle = (id) => {
      if (id) {
          const shortId = jobId.slice(-5);  // Get last 5 characters
          document.title = shortId;
      } else {
          document.title = 'DirectIt INX';
      }
  };

      const handleMenu = (val) => {
        setIsMenuOpen(true);
        setCategory(val);        
      };



      const loadTripDetails = useCallback((jobId) => {  
        // Update the document title here as well, with a delay
        setTimeout(() => {
          if (jobId) {
            const shortId = jobId.slice(-5);  // Get last 5 characters
            document.title = `${shortId} - ${viewMappings[showContent]}`;
          }
        }, 100);
      
        getTripServiceNotes(jobId);    
        httpRequest.getTrip(accessToken, jobId).then((response) => {
          //console.log(JSON.stringify(response.data));
          var data = response.data;
          try {        
            data.dtCallIn_display = data.dtCallIn?.toString().substring(0, 19).replace("T", " ");
            data.dtDCT_display = data.dtDCT?.toString().substring(0, 19).replace("T", " ");
      
            if (data.dtDelivered) 
              data.dtDelivered_display = data.dtDelivered.toString().substring(0, 19).replace("T", " ");
            if (data.eta)
              data.eta_display = data.eta.toString().substring(0, 19).replace("T", " ");
            if (data.URL)
              data.FedexTrackNum = data.URL.substring(data.URL.length - 12);
      
            setIsLattice(data.isLattice);
            var images = JSON.parse(data.ImageURLs);
            if (images) {
              var imageList = [];
              images.forEach((value) => {
                let fileExt = value.url.split(".").pop();
                value.savedTimeUTC = new Date(moment(value.savedTimeUTC).toDate() - new Date().getTimezoneOffset() * 60000); //UTC -- Locatime display
                value.savedTime = moment(value.savedTimeUTC).format("YY-MM-DD HH:mm");
                value.id = imageList.length;   
                value.IsImageOrPDF = !(value.url.includes(".doc") || value.url.includes(".docx") || value.url.includes(".xls") || value.url.includes(".xlsx") ||value.url.includes(".csv"));         
                value.IsImage = !(value.url.includes(".doc") || value.url.includes(".docx") ||value.url.includes(".pdf") || value.url.includes(".xls") || value.url.includes(".xlsx") || value.url.includes(".csv"));
               
                value.fileType = (value.url.includes(".doc") || value.url.includes(".docx")) ? "doc" :(value.url.includes(".xls") || value.url.includes(".xlsx")) ? "xls"
                  :value.url.includes(".pdf") ? "pdf": value.url.includes(".csv") ? "csv" :"image";                
                value.fileExt = value.url.split(".").pop().includes("net/") ? "png" : fileExt;
                imageList.push(value);
              });
              setPhotoArray(imageList);             
            }
            data.serviceMinutes = data.serviceMinutes || 0;
            data.serviceDays = data.serviceDays || 0;
            data.billMinutes = data.billMinutes || 0;
            data.drivDist = data.drivDist || 0;
            setOrder(data);
            setViewAddress({
              companyName: data.cCompanyName,
              lat: data.cLat,
              long: data.cLong,
              address: data.cAddress,
            });
            setAddr("delivery");
            setGPSUpdateButtonText("Update Delivery GPS");         
      
            setOrderDTCallIn(moment(moment_tz.tz(data.dtCallIn_display, "Canada/Mountain").toDate()).format());            
            setOrderDTDCT(moment(moment_tz.tz(data.dtDCT_display, "Canada/Mountain").toDate()).format());
            if (data.eta) 
              setOrderSCT(moment_tz.tz(data.eta_display, "Canada/Mountain").toDate());  
      
            if (data.logo?.data) {
              var binary = "";
              var bytes = new Uint8Array(data.logo?.data);
              var len = bytes.byteLength;
              for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
              }
              const imageSrc = "data:image/png;base64," + btoa(binary);
              setCompanyLog(imageSrc);             
            }
      
            getGPSTracking();    
            getTripAccountList(data.jobID, data.ACCOUNT);
            httpRequest.getOrderTrack(jobId).then((response) => {
              try {
                const tempTrackList = response.data?.map((option,index) => {
                  option.id=index;
                  return option;
                });
                setSteps(tempTrackList);                
              } catch (e) {
                console.log("getOrderTrack exception:" + e);
              }
            });
            setIsUploadFile(false);  
            
            loadTripTags();
      
            if (data.Status < 8) {
              if (interval === null) {
                let _interval = setInterval(() => {getGPSTracking();}, 30000);
                setInterval(_interval);              
              }
            } else {
              if (interval !== null) {
                clearInterval(interval);
                setInterval(null); 
              }
            }    
            getRateChoices(data.SER_AREA, data.SER_TIME);
          } catch (err) {
            console.log("Order not found. Please try again-" + err);
            //alert("Order not found. Please try again-" + err);
          }
        });
      }, []);

    

    const loadTripTags = () =>{
      httpRequest.getTripTags(accessToken,jobId,'TripDetail').then((response) => {
        console.log('getTripTags',JSON.stringify(response.data));                   
        setTagList(response.data);
      });
    }

    const getRateChoices = (ser_area, ser_time) => {
        httpRequest.getRateChoice(accessToken,jobId).then((response) => {
            //console.log("getRateChoices",JSON.stringify(response.data));
            try {               
                let newRows = response.data.map((item,i) => {
                     return {
                        ...item,
                        id:i,
                        service: item.ser_area + item.ser_time,
                    };
                }); 
               // console.log("getRateChoices",JSON.stringify(newRows));
                setRateChoices(newRows);     
                var _currentRate = newRows.find((obj) => {return (obj.ser_area === ser_area && obj.ser_time === ser_time);});
                if (_currentRate !== undefined){
                    console.log("getRateChoices currentRate is " + JSON.stringify(_currentRate));
                    setCurrentRate(_currentRate);
                }
                //setCurrentRate(newRows.find((obj) => {return (obj.ser_area === order.SER_AREA && obj.ser_time === order.SER_TIME);}));                  
            } catch (e) { console.log("getRateChoices exception", e); }
        });
    };
    
    const getTripServiceNotes = (jobId) => {
        httpRequest.getTripServiceNotes(accessToken, jobId).then((body) => {
            try {
              console.log("getTripServiceNotes", JSON.stringify(body.data))
              let tripServiceNoteList = [];
              if (body.data.length > 0) {
                let tripServiceAccountNotes = "";
                body.data.forEach((row) => {
                  if (row.source?.toLowerCase().includes("account") && row.notes)
                    tripServiceAccountNotes += "AccountInfo:" + row.notes + ";";
                  else if (row.notes !== null && row.notes !== "")
                    tripServiceNoteList.push(row);
                });
                setTripServiceNoteList(tripServiceNoteList);
                setTripServiceAccountNotes(tripServiceAccountNotes);                
              }
            } catch (e) {console.log("getTripServiceNotes exception", e);}
        });
    };

    const getPackageList = () => {
        httpRequest.getTripPackageDescriptions(accessToken, jobId).then((response) => {
            setPackageList(response.data);
        });
    };
    
    const getGPSTracking = () => {
        httpRequest.getJobGPS(jobId).then((response) => {
          setGpsData(response.data);  
          if (response.data.TripLocation.includes("Delivered")) {
            if (interval !== null) {
              clearInterval(interval);
              setInterval(null);
            }
          }
        });
    };

    /* [{"account":"9905","AccountName":"DIRECTIT SERVICE FAIL."},{"account":"9910","AccountName":"DirectIT Group"},{"account":"M05102","AccountName":"C.R. Wall & Co. Inc."}] */
    const getTripAccountList = (jobId, account) => {
      console.log("getTripAccountList called with jobId:", jobId, "account:", account);
      httpRequest.getTripAccountList(accessToken, jobId).then((body) => {
        console.log("getTripAccountList response:", body);
        try {
          const accountData = Array.isArray(body.data) ? body.data : [];
          setAccountList(accountData);
          const currentAcct = accountData.find(obj => obj.account === account?.trim());
          if(currentAcct) {
            setCurrentAccount(currentAcct);
          }
        } catch (e) {
          console.error("getTripAccountList exception", e);
          setAccountList([]);
        }
      }).catch(error => {
        console.error("Error in getTripAccountList:", error);
        setAccountList([]);
      });
    };

    const getReferenceList = () => {
        httpRequest.getReferenceFields(accessToken, jobId).then((body) => {
            console.log("getReferenceFields", JSON.stringify(body.data));
            setReferenceList(body.data);
            body.data.forEach((item) => {
              if (item.typeDesc === "DropDown")
                getReferenceDropDown(item.refID);
            });
          });
      };
    
    const getReferenceDropDown = (id) => {
        httpRequest.getReferenceDropDown(accessToken, id).then((response) => {
            console.log("getReferenceDropDown", JSON.stringify(response.data));
            let refList = referenceList;
            var objIndex = refList.findIndex((obj) => obj.refID === id);
            refList[objIndex].ddValues = response.data;
            setReferenceList(refList);
        });
    };   
    
    const getUserEmails = () => {
        httpRequest.getUserEmails(order.ACCOUNT).then((response) => {
            setCallerList(response.data);
        });
      };
    
    const  removeCallerFromList = (caller) => {
        let nameList = callerList.filter(function (obj) {return obj.username !== caller;});
        setCallerList(nameList);
    };
    
    const rendAccountServiceNotes = () => {
        return TripServiceNoteList.map((item) => (
          <Alert variant="filled"  severity="info"  className={"fieldJobNote"}  icon={false}>
            <div className={"speechBubble"}>{item.source}</div>
            <div className={"notes"}>{item.notes}</div>
          </Alert>
        ));
    };

    
    const updateOrderFields = (jobId) => {
      try {
        let updateObj = { ...updatingItem, jobID: jobId };        
        // Handle date fields
        const dateFields = ['dtCallIn', 'dtDCT', 'sct'];
        dateFields.forEach(field => {
          if (updateObj[field]) {
            // Convert to UTC
            //updateObj[field] = moment(updateObj[field]).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            updateObj[field] = moment(updateObj[field]).toDate() - new Date().getTimezoneOffset() * 60000;
          }
        });
    
        if (updateObj.hasOwnProperty("del_inst") && updateObj.del_inst.length === 0) {
          updateObj.del_inst = "  "; 
        }
        
        console.log("updateJobFields", JSON.stringify(updateObj));
    
        httpRequest.updateJobFields(accessToken, updateObj)
          .then((body) => {
            console.log("updateJobFields response:", JSON.stringify(body.data));
            return httpRequest.updateJobRateFields(accessToken, updateObj);
          })
          .then((body) => {
            console.log("updateJobRateFields response:", JSON.stringify(body.data));
            setOpenUpdatingDialog(true);
            loadTripDetails(jobId);  // Refresh the trip details
          })
          .catch(err => {
            console.error("Failed to update job field:", err);
            setUpdatingItmeStr(`Error updating job: ${err.message}`);
            setOpenUpdatingDialog(true);
          });
      } catch (e) {
        console.error("updateOrderFields exception:", e);
        setUpdatingItmeStr(`Error: ${e.message}`);
        setOpenUpdatingDialog(true);
      }
    };
    
    const postUpdatedReferences = (jobID) => {
        try {
          if (JSON.stringify(updatingReferences) !== "{}") {
            for (let key in updatingReferences){
              if (updatingReferences.hasOwnProperty(key)) {  
                var reference = {
                  refID: key,
                  value: updatingReferences[key],
                };
                console.log("postUpdatedReferences- " + JSON.stringify(reference));
                httpRequest.postReferenceFields(accessToken, jobID, reference).then((body) => {
                      console.log("postReferenceFields",JSON.stringify(body.data));
                },
                function errorCallback(err) {
                    alert("Failed to update reference editTripService field.");
                });  
              }           
            }
          }
        } catch (e) {console.log("postUpdatedReferences exception" + e);}
    };
    
    const getTripFunctions = (jobId) => {
        httpRequest.getTripFunctions(accessToken, jobId).then((response) => {
            try {
               let viewFuncs = [];
               let dispatchFuncs = [];
               let serviceFuncs = [];
               let ratingFuncs = [];    
                //console.log(JSON.stringify(response.data));
                response = response.data;
                for (const x in response[1][0]) {
                    let row = { functionText: x };
                    if (x !== "rating") {
                        if (x === "Drivers") 
                            row.functionText = "Drivers&rating";                  
                        viewFuncs.push(row);
                    }
                }
                //console.log(viewFunctions);    
                response[2].map((row) => {
                   // console.log(row);
                    if (row.section.includes("Dispatch")) 
                       dispatchFuncs.push(row);                
                    if (row.section.includes("Service")) 
                       serviceFuncs.push(row);                
                    if (row.section.includes("Rating")) 
                       ratingFuncs.push(row);                 
                });
                setViewFunctions(viewFuncs);
                setDispatchFunctions(dispatchFuncs);
                setServiceFunctions(serviceFuncs);
                setRatingFunctions(ratingFuncs);    
            } catch (e) {console.log("getTripFunctions exception", e);}
        });
    };
   
    const  toggleGallery = (visible) => {
        console.log("toggleGallery - " + visible);
        setGalleryVisible(visible);
        if (!visible && isUploadFile)
           loadTripDetails(jobId);
    };
    
    const currentDateTime = () => {
        var current = new Date();
        return new Date(current.getFullYear(), current.getMonth(), current.getDate(), current.getHours(),current.getMinutes());
    };

    const performTripFunction = (action, category) => {
      try {
        if (category === "View") {
          setSelectedTab(
            action.functionText === "map" ? 0 : 
            action.functionText === "pictures" ? 1 : 
            action.functionText === "History" ? 2 :
            action.functionText === "Tags" ? 4 : 
            action.functionText === "Drivers&rating" ? 3 : 0
          );
          setIsMenuOpen(false);
        } else {
          const menuOptions = 
            category === "Dispatch" ? dispatchFunctions : 
            category === "Service" ? serviceFunctions : 
            ratingFunctions;
          
          const selectedFunction = menuOptions.find(row => row.functionText === action.functionText);
          if (selectedFunction) {
            processFunction(selectedFunction, category);
          }
        }
      } catch (e) {
        console.log("performTripFunction exception:", e);
      }
    };
    
    const processFunction = (row, category) => {
      console.log("processFunction row:", JSON.stringify(row));
      if (row.paramaters !== null && row.paramaters.length !== 0) {
        try {
          row.paramaters = row.paramaters.split("[").join("{").split("]").join("}");         
          row.paramaters = row.paramaters.replace('"values":{', '"values":[');
          row.paramaters = row.paramaters.replace('},"default"', '],"default"');
          const obj = JSON.parse(row.paramaters);
          const functionParamsList = Object.keys(obj).map(key => {
            const funcObj = obj[key];
            const baseParam = {
              label: key,
              prompt: funcObj.prompt,
              type: funcObj.type,
              length: funcObj.length,
              mo: funcObj.mo,
            };
    
            switch (funcObj.type) {
              case "dropdown":
                return {
                  ...baseParam,
                  default: funcObj.default,
                  list: funcObj.values,
                  value: "",
                };
              case "datetime":
                return {
                  ...baseParam,
                  date_val: currentDateTime(),
                  time_val: currentDateTime(),
                  value: currentDateTime(),
                };
              case "decimal":
                return {
                  ...baseParam,
                  value: "",
                  precision: funcObj.precision,
                };
              case "checkbox":
                return {
                  ...baseParam,
                  default: funcObj.default || true,
                  value: funcObj.default || true,
                };
              default:
                return {
                  ...baseParam,
                  value: "",
                };
            }
          });
    
          postFunctionParameters(functionParamsList, row.procedureName, row.hasOverride, row.RefreshAfter, row.functionText);
        } catch (e) {
          console.log("Error parsing parameters:", e);
        }
      } else {
        if (row.procedureName === null) {
          alert("No procedure for this function!");
        } else {
          httpRequest.doTripFunction(accessToken, jobId, [], row.procedureName).then(
            async (body) => {
              setMessageInfo(`${row.procedureName} procedure is done!`);
              setOpenSnack(true);                 
              if (row.RefreshAfter) {
                loadTripDetails(jobId);
                if (category === "Rating") {
                  NoticeEmitter.emit("refreshRating", "");
                }
              } else {
                getTripFunctions(jobId);
              }            
            },
            function errorCallback(err) {
              alert("Failed to finish procedure " + row.procedureName + ". Error is: " + err);
            }
          );
        }
      }
    };
    
    
    const postFunctionParameters = (paramsList, procedureName, isOverride, refreshAfter, functionText) => {
      setTripFunction({
        parameters: paramsList,
        procedureName: procedureName,
        isOverride: isOverride,
        refreshAfter: refreshAfter,
        jobId: jobId,
        accessToken: accessToken,
        functionText: functionText
      });
      setDialogIsOpen(true);       
    };

    const handleDialogClose = () => {
      setDialogIsOpen(false);
    };

    const renderFieldWithInputAdornment = (val, sign, position) => {
      return (
        position === "start" ?
          <InfoField value={val}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ margin: "auto", marginRight: 10 }}>
                  {sign}
                </InputAdornment>
              )
            }}
          /> :
          <InfoField value={val}
            InputProps={{
              endAdornment: (
                <InputAdornment style={{ margin: "auto", marginRight: 5 }}>
                  {sign}
                </InputAdornment>
              ),
            }}
          />
      )
    };
  
    const renderEditFieldWithInputAdornment = (name, type, val, sign, position, onChangeHandler, onBlurHandler) => {
      return (
        position === "start" ?
          <TextField name={name} type={type}
            defaultValue={val}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ margin: "auto", marginRight: 10 }}>
                  {sign}
                </InputAdornment>),
            }}
          /> :
          <TextField name={name} type={type}
            defaultValue={val}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment style={{ margin: "auto", marginRight: 10 }}>
                  {sign}
                </InputAdornment>),
            }}
          />
      )
    };
  
    const renderDropdown = (name, value, options, keyName, labelName, size) => {
      console.log("renderDropdown called with:", { name, value, options, keyName, labelName, size });
      
      if (!Array.isArray(options) || options.length === 0) {
        console.warn(`No options provided for dropdown: ${name}`);
        return <InfoField value={value || 'N/A'} />;
      }
      
      return (
        <TextField
          name={name}
          variant="outlined"
          select
          size="small"
          className="editableJobField"
          value={value || ''}
          style={{ width: size }}
          onChange={(event) => {
            handleValueChange(event);
          }}
        >
          {options.map((item, index) => {
            if (!item || typeof item !== 'object') {
              console.warn(`Invalid item at index ${index} for dropdown: ${name}`);
              return null;
            }
            return (
              <MenuItem key={item[keyName] || index} value={item[keyName] || ''}>
                {item[labelName] || ''}
              </MenuItem>
            );
          })}
        </TextField>
      );
    };

    const renderSideMenu = () => {
        return (
          <div class="side-icons">
            <button  class="btn btn-warning slide-icon" onClick={() => handleMenu("View")} onMouseOver={() => handleMenu("View")}>
              <i class="fa fa-eye" aria-hidden="true"></i>
              <span>View</span>
            </button>
            <button  class="btn btn-danger slide-icon" onClick={() => handleMenu("Dispatch")} onMouseOver={() => handleMenu("Dispatch")}>
              <i class="fa fa-truck" aria-hidden="true"></i>
              <span>Dispatch</span>
            </button>
            <button class="btn btn-success slide-icon" onClick={() => handleMenu("Service")} onMouseOver={() => handleMenu("Service")}>
              <i class="fa fa-wrench" aria-hidden="true"></i>
              <span>Service</span>
            </button>
            <button class="btn btn-primary slide-icon"  onClick={() => handleMenu("Rating")} onMouseOver={() => handleMenu("Rating")}>
              <i class="fa fa-sliders" aria-hidden="true"></i>
              <span>Rating</span>
            </button>
          </div>
        );
    };
    
    const renderMenu = (category) => {
        let menuOptions =  category === "View"?viewFunctions:category === "Dispatch"?dispatchFunctions:category === "Service"?serviceFunctions:ratingFunctions;        
        return (
          <Drawer
            style={classes.drawerWrapper}  className={"transparentDrawer"}
            elevation={0}  anchor="left"  hideBackdrop="true" variant="persistent"  open={isMenuOpen}  onClose={() => setIsMenuOpen(false)}>
            <div  style={classes.drawerList} className={`${category} drawerList`} onMouseLeave={() => setIsMenuOpen(false)}>
              <div style={classes.drawerHeader} className={"drawerHeader"}>
                <Typography variant="h5" width="70%">{category}</Typography>
                <IconButton aria-label="delete"> <CloseIcon onClick={() => setIsMenuOpen(false)}  onMouseOver={() => setIsMenuOpen(false)}/></IconButton>
              </div>
              <Card elevation={0}>
                <List dense={true} disablePadding={true}>
                  {menuOptions.map((action) => (
                    <div className={"listItemWrapper"}>
                      <ListItem button key={action.functionText} divider={true} onClick={() => performTripFunction(action, category)}>
                        <ListItemText  primary={action.functionText} style={{ textTransform: "capitalize" }}/>
                      </ListItem>
                    </div>
                  ))}
                </List>
              </Card>
            </div>
          </Drawer>
        );
    };
       
    const handleValueChange = (event) => {
      console.log(`handleValueChange - ${event.target.name} - ${event.target.value}`);  
      setUpdatingItem((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value === "" ? " " : event.target.value,        
      }));        
    };     
    
    const  handleGPSChange = (lat, lng, source) => {
        setLatestUpdatedPostion({lat: lat, lng: lng,});
        setUpdateGPSSource(source);       
    };

    const updateGPS = () => {
        var paramsList = [];
        paramsList.push({
          label: "lat",
          prompt: "Latitude",
          type: "decimal",
          mo: "Latitude",
          precision: "6",
          value: latestUpdatedPostion.lat.toFixed(6),
        });
        paramsList.push({
          label: "long",
          prompt: "Longitude",
          type: "decimal",
          mo: "Longitude",
          precision: "6",
          value: latestUpdatedPostion.lng.toFixed(6),
        });
        var spName = addr.includes("delivery")? "in5.trip_updateConGPS" : "in5.trip_updateShipGPS";
        httpRequest.doTripFunction(accessToken, jobId,paramsList,spName).then(async (body) => {
              console.log("doTripFunction-" + body.data);
            },
            function errorCallback(err) {
              alert("Failed to finish trip_assign procedure. Error is : " + err);
            }
        );
        let _order = order;
        if (addr.includes("delivery")) {
            _order.cLat = latestUpdatedPostion.lat.toFixed(6);
            _order.cLong = latestUpdatedPostion.lng.toFixed(6);
        } else {
           _order.sLat = latestUpdatedPostion.lat.toFixed(6);
           _order.sLong = latestUpdatedPostion.lng.toFixed(6);
        }
        setOrder(_order);
    };
    
    const updateOrder = () => {
      try {
        console.log("======updateOrder- " + JSON.stringify(updatingItem));
        if (updatingItem.hasOwnProperty("caller") && updatingItem.caller === '') {
          setUpdatingItmeStr("Please input caller!");
          setOpenUpdatingDialog(true);
          return;
        }
        setIsEdit(false);         
        let keyArry = Object.keys(updatingItem);
        let info = "";  
        keyArry.forEach((item) => {
          switch(item) {
            case "del_inst": info += "Instruction,"; break;
            case "pieces": info += "Piece number,"; break;
            case "emailConf": info += "Email,"; break;
            case "sigReq": info += "No Signature Required,"; break;
            case "dtCallIn": 
            case "readyAt": info += "Ready At,"; break;
            case "dtDCT": info += "DCT,"; break;
            case "Desc": info += "Package Type,"; break;
            default: info += item + ",";
          }
        });         
        if (Object.keys(updatingReferences).length > 0) {
          info += "Reference";
        }
        setUpdatingItmeStr(`Updated items: ${info}`);
    
        // Ensure the correct field name is used
        if (updatingItem.readyAt) {
          updatingItem.dtCallIn = updatingItem.readyAt;
          delete updatingItem.readyAt;
        }
    
        console.log("Sending update with:", updatingItem);
        updateOrderFields(jobId);
      } catch (e) {
        console.log(`updateOrder exception- ${e}`);
      }
    };

    const handlePiecesChange = (event) => {
        if (event.target.value < 0) 
          event.target.value = 0;      
        if (event.target.value.toString().length > 3) {
          let temp = event.target.value.toString().substring(0, 3);
          event.target.value = temp;
        }
    };
    
    const  handleMaxLengthFiveChange = (event) => {
        if (event.target.value < 0) 
          event.target.value = 0;        
        if (event.target.value.toString().length > 5) {
          let temp = event.target.value.toString().substring(0, 5);
          event.target.value = temp;
        }
    };
    
    const handlerEditClick = () => {
        console.log("handlerEditClick currentRate is " + JSON.stringify(currentRate));
        setIsEdit(true);
        getUserEmails();
        getPackageList();
        getReferenceList();
        setUpdatingItem({});
        setUpdatingReferences({});       
    };

    const renderLabel = (text) => {               
        return (<Typography variant="button"  color="primary" style={classes.jobDetailLabel}> {text} </Typography>)
    };    

    const renderDatetimePicker = (name, value, setStateFunc) => {               
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker name={name} className="editableJobField" fullWidth
              format="yyyy/MM/dd HH:mm"  value={value}
              onChange={(input) => {
                setUpdatingItem((prevState) => {
                    return {...prevState, [name]: input,};
                }); 
                setStateFunc(input);                       
              }}
            />
          </MuiPickersUtilsProvider>
        )
    };   

    const renderMultilineText = (name, val, backgroundColor, border, textColor) =>{
        return(
            <TextInput inputType="text"  name={name}  multiline  minRows={1}  hideLabel={true}
                value={val} disabled
                style={{
                   backgroundColor: backgroundColor,
                   borderRadius: "4px",
                   color: textColor? textColor: "#fff",
                   color: textColor? textColor: "#fff",
                   border: border,
                }}
            />
        )
    }  
    
    const renderList = (show,SetShow, title,Component) =>{
      return(
        <Grid item md={12}>
        <List>
          <ListItem onClick={()=>{SetShow(!show)}} style={{ padding: 0 }}>
            <ListItemText  disableTypography
              primary={<Typography style={{fontSize: 14, fontWeight: 420, color: "#ffffff"}}> {title} </Typography>}
            />
            {show ? (<ExpandLess color="primary" /> ) : (<ExpandMore color="primary" /> )}
          </ListItem>
          <Collapse in={show} timeout="auto" unmountOnExit>
            <Component jobId={jobId}  accessToken={accessToken}></Component>
          </Collapse>
        </List>
        </Grid>)
    }

    
    return (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: `100%`, 
        overflow: 'hidden',
        position: 'fixed',
        top: '64px',
        left: 0,
        right: 0,
        bottom: 0
      }}>
        <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
          <SideMenu
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            navigation={navigation}
            performTripFunction={performTripFunction}
          />
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <LatticeStatusBar
              order={order}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              updateOrder={updateOrder}
              jobId={jobId}
              companyLogo={companyLogo}
              handlerEditClick={handlerEditClick}
              orderSCT={orderSCT}
              setOrderSCT={setOrderSCT}
              setUpdatingItem={setUpdatingItem}
              orderDTDCT={orderDTDCT}
              setOrderDTDCT={setOrderDTDCT}
              orderDTCallIn={orderDTCallIn}
              setOrderDTCallIn={setOrderDTCallIn}
              dtPickupBy={dtPickupBy}
              setDTPickupBy={setDTPickupBy}
              dtDontDeliveryBefore={dtDontDeliveryBefore}
              setDTDontDeliveryBefore={setDTDontDeliveryBefore}
              setAdjustRate={setAdjustRate}
              isLattice={isLattice}
            />
            <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
              <div style={{ padding: '20px' }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ width: "100%", height: 'auto', overflow: 'visible' }} spacing={1}>
                  <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{ margin: "0", width: "100%" }} sm={7} lg={9}>
                    <Grid item xs={12}>
                      {TripServiceNoteList.length > 0 && rendAccountServiceNotes()}
                    </Grid>
                    <Grid item xs={11} lg={6} className="shipperCardBlock darkCardBlock">
                      {order && (
                        <AddressCard
                          jobId={jobId}
                          type="shipper"
                          accessToken={accessToken}
                          accountNumber={order?.ACCOUNT}
                          coord={{ lat: order?.sLat, long: order?.sLong }}
                          data={{
                            companyName: order?.sCompanyName,
                            address: order?.sAddress,
                            suite: order?.sSuite,
                            quad: order?.sQuad,
                            city: order?.sCity,
                            prov: order?.sProv,
                            country: order?.sCountry,
                            postal: order?.sPostal,
                            contactName: order?.sContact,
                            contactPhone: order?.sPhone,
                            contactID: order?.shipContactID,
                            addressID: order?.shipAddressID,
                            lat: order?.sLat,
                            long: order?.sLong,
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={11} lg={6} className="consigneeCardBlock darkCardBlock">
                      {order && (
                        <AddressCard
                          jobId={jobId}
                          type="consignee"
                          accessToken={accessToken}
                          accountNumber={order?.ACCOUNT}
                          coord={{ lat: order?.cLat, long: order?.cLong }}
                          data={{
                            companyName: order?.cCompanyName,
                            address: order?.cAddress,
                            suite: order?.cSuite,
                            quad: order?.cQuad,
                            city: order?.cCity,
                            prov: order?.cProv,
                            country: order?.cCountry,
                            postal: order?.cPostal,
                            contactName: order?.cContact,
                            contactPhone: order?.cPhone,
                            contactID: order?.conContactID,
                            addressID: order?.conAddressID,
                            lat: order?.cLat,
                            long: order?.cLong,
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} className="tab-container" style={{ overflow: 'visible' }}>
                      <Tabs
                        dataSource={viewTabs.map(tab => ({
                          ...tab,
                          disabled: !isLattice && (tab.id === 4 || tab.id === 5)
                        }))}
                        selectedIndex={selectedTab}
                        onItemClick={(e) => {
                          if (!e.itemData.disabled) {
                            updateView(e.itemData.id);
                          }
                        }}
                        itemRender={(item, index) => (
                          <Tooltip
                            title={item.disabled ? "Only Available on Lattice Trip" : ""}
                            arrow
                            placement="top"
                          >
                            <div className={`tab ${selectedTab === index ? 'tab-selected' : ''} ${item.disabled ? 'tab-disabled' : ''}`}>
                              {item.text}
                            </div>
                          </Tooltip>
                        )}
                      />
                    </Grid>
      
                    <Grid item xs={12}>
                      {selectedTab === 0 && (
                        <>
                          <MapView
                            view={view}
                            setView={setView}
                            viewAddress={viewAddress}
                            order={order}
                            addr={addr}
                            setAddr={setAddr}
                            photoArray={photoArray}
                            handleGPSChange={handleGPSChange}
                            gpsData={gpsData}
                            GPSUpdateButtonText={GPSUpdateButtonText}
                            setLocationUpdateDialog={setLocationUpdateDialog}
                          />
                          <OrderSteps steps={steps} />
                        </>
                      )}
                      {selectedTab === 1 && (
                        <PicturesTab 
                          viewAddress={viewAddress}
                          addr={addr}
                          order={order}
                          photoArray={photoArray}
                          isStreetViewExpanded={isStreetViewExpanded}
                          setIsStreetViewExpanded={setIsStreetViewExpanded}
                          handleGPSChange={handleGPSChange}
                          toggleGallery={toggleGallery}
                          galleryVisible={galleryVisible}
                          accessToken={accessToken}
                          jobId={jobId}
                          setIsUploadFile={setIsUploadFile}
                          classes={classes}
                        />
                      )}
                      {selectedTab === 2 && <TripHistoryList jobId={jobId} accessToken={accessToken} />}
                      {selectedTab === 3 && (
                        <>
                          <Grid item md={12}><TripDriverList jobId={jobId} accessToken={accessToken} /></Grid>
                          <Grid item md={12}><TripRatingList jobId={jobId} accessToken={accessToken} isLattice={isLattice} RateSummaryLine={order.RateSummaryLine} /></Grid>
                        </>
                      )}
                      {isLattice && selectedTab === 4 && <TagsView jobId={jobId} accessToken={accessToken} loadTripTags={loadTripTags} />}
                      {isLattice && selectedTab === 5 && <TripPiecesList jobId={jobId} accessToken={accessToken} />}
                    </Grid>
                  </Grid>  
                            
                  <OrderDetails 
                    order={order}
                    isEdit={isEdit}
                    currentAccount={currentAccount}
                    accountList={accountList}
                    callerList={callerList}
                    handleValueChange={handleValueChange}
                    renderDropdown={renderDropdown}
                    renderFieldWithInputAdornment={renderFieldWithInputAdornment}
                    renderEditFieldWithInputAdornment={renderEditFieldWithInputAdornment}
                    packageList={packageList}
                    currentRate={currentRate}
                    removeCallerFromList={removeCallerFromList}
                    getUserEmails={getUserEmails}
                    handlePiecesChange={handlePiecesChange}
                    handleMaxLengthFiveChange={handleMaxLengthFiveChange}
                    tagList={tagList}
                    isLattice={isLattice}
                    jobDetailLabelStyle={classes.jobDetailLabel}
                  />
                  
                  <Grid item xs={12} style={{ marginTop: "16px", textAlign: "center" }}>
                    {!isLattice && jobId && (
                      <Button
                        component={Link}
                        to={`/legacyJobDetail/${jobId}`}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'none' }}
                      >
                        View in Legacy Job Details Page
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
    
        <DialogManager 
          openUpdatingDialog={openUpdatingDialog}
          locationUpdateDialog={locationUpdateDialog}
          updatingItemStr={updatingItmeStr}
          updateGPSSource={updateGPSSource}
          latestUpdatedPosition={latestUpdatedPostion}
          setOpenUpdatingDialog={setOpenUpdatingDialog}
          setLocationUpdateDialog={setLocationUpdateDialog}
          loadTripDetails={loadTripDetails}
          updateGPS={updateGPS}
          jobId={jobId}
          dialogIsOpen={dialogIsOpen}
          tripFunction={tripFunction}
          handleDialogClose={() => setDialogIsOpen(false)}
        />
    
        <SnackbarManager 
          openSnack={openSnack}
          setOpenSnack={setOpenSnack}
          messageInfo={messageInfo}
        />
    
        <footer style={{ flexShrink: 0 }}>
          <Copyright textColor='white'/>
        </footer>
      </div>
    );
}