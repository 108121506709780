import React from "react";
import { useHistory  } from 'react-router-dom';
import Copyright from "../Copyright";
import "date-fns";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import HttpRequest from "../HttpService";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplyIcon from "@material-ui/icons/Reply";
import Box from "@material-ui/core/Box";
import Moment from "moment";
import {Dialog,DialogTitle,DialogContent,DialogActions,} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@mui/material/Switch";
import CustomDialog from "../components/dialog/Dialog";
import RouteIcon from "@mui/icons-material/Route";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BoltIcon from "@mui/icons-material/Bolt";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaidIcon from "@mui/icons-material/Paid";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import AttachmentIcon from "@mui/icons-material/Attachment";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CounterUpTimer from "./counterUpTimer";

const classes = {
  root: {
    backgroundColor: "#010C11",
  },
  appBar: {
    overflow: "auto",
  },
  itemBox: {
    borderWidth: "1px",
    padding: "10px",
    borderRadius: "6px",
    boxSizing: "border-box",
  },
  boxNumber: {
    fontSize: 18,
    textAlign: "right",
    wordWrap: "break-word",
  },
  boxCaption: {
    fontSize: 12,
    whiteSpace: "nowrap",
    paddingLeft: "5px",
    height: "24px",
  },
  tripAssignBoxCaption: {
    fontSize: 12,
    color: "black",
  },
  leftNotices: {
    fontSize: 14,
  },
  searchBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  invisible: {
    display: "none",
  },
  oddRow: {
    backgroundColor: "#EEE",
  },
  evenRow: {
    backgroundColor: "#FFF",
  },
};

class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: localStorage.getItem("access_token"),
      companyID: props.companyID,
      tripStats: {},
      messages: [],
      orgMessages: [],
      numDrivers: 0,
      numTrips: 0,
      msgBoxOpen: false,
      msgToDriver: "",
      selectedDriver: "",
      serviceChecked: false,
      dispatchChecked: false,
      isOpenMessageSearch: false,
      tripAssignStats: 0,
      openWarningDialog: false,
    };
  }

  setPageTitle = () => {
    document.title = "Dashboard";
  };

  componentDidMount = () => {
    console.log("dashboard useEffect");
    let token = localStorage.getItem("access_token");
    if (token === undefined || token === null) window.location.href = "/";

    setTimeout(this.setPageTitle, 100);

    this.pollData();
    const interval = setInterval(() => {
      this.pollData();
    }, 60000);
    return () => clearInterval(interval);
  };

  pollData = () => {
    console.log("getServiceMessages:-serviceChecked:" +  this.state.serviceChecked + "---dispatchChecked:" +   this.state.dispatchChecked);
    let allMessages = [];

    var id = 0;
    var numServiceAlerts = 0;
    HttpRequest.getDriverStats(
      this.state.accessToken,
      this.state.companyID
    ).then(async (body) => {   
      this.setState({
        tripStats: {
          ...this.state.tripStats,
          DriversLoggedIn: body.data.length,
        },
      });
      console.log(this.state.tripStats);
    });
   
   
    HttpRequest.getServiceMessages(this.state.accessToken).then(
      async (body) => {
        // console.log("getServiceMessages:" + JSON.stringify(body.data));
        try {
          var assignTripsCount = 0;
          await body.data.forEach((message) => {
            message.datestamp = Moment(message.datestamp).format(
              "YYYY-MM-DD HH:mm"
            );
            const newMessage = {
              ...message,
              id: id,
            };
            id++;
            allMessages.push(newMessage);
            numServiceAlerts++;

            if(message.messageText.includes("Trip Requires Dispatch (no Auto)"))
              assignTripsCount++;              
          });
          ////objSort(allMessages,  ['companyID',false],['priority',false], ['datestamp', false]);
          ////allMessages.sort((a, b) => a.priority > b.priority ? -1 : 1)
          this.setState({ orgMessages: allMessages });
          this.setState({ tripAssignStats: assignTripsCount });

          if (
            (this.state.serviceChecked && this.state.dispatchChecked) ||
            (!this.state.serviceChecked && !this.state.dispatchChecked)
          ) {
            allMessages.sort((a, b) =>
              a.priority > b.priority
                ? -1
                : a.priority === b.priority && a.datestamp < b.datestamp
                ? -1
                : 1
            );
            this.setState({ messages: allMessages });
          } else {
            //console.log("getServiceMessages:-" + this.state.serviceChecked + "---" + this.state.dispatchChecked);
            if (this.state.serviceChecked && !this.state.dispatchChecked)
              allMessages = allMessages.filter((x) => x.messageType === 1);
            else if (!this.state.serviceChecked && this.state.dispatchChecked)
              allMessages = allMessages.filter((x) => x.messageType === 2);
            allMessages.sort((a, b) =>
              a.priority > b.priority
                ? -1
                : a.priority === b.priority && a.datestamp < b.datestamp
                ? -1
                : 1
            );
            this.setState({ messages: allMessages });
          }
          //updateServiceMessageList(serviceChecked, dispatchChecked);
        } catch (e) {
          console.log("getServiceMessages exception:" + e);
        }
      }
    );

    HttpRequest.getTripStats(this.state.accessToken, this.state.companyID).then(      
      async (body) => {
        console.log("getTripStats:" + JSON.stringify(body.data));
        await body.data.forEach((stat) => {        
          this.setState({
            tripStats: {
              ...this.state.tripStats,
              ...stat,
              ServiceAlerts: numServiceAlerts,
            },
          });
        });
      }
    );
  };

  updateServiceMessageList = (IsServiceChecked, IsDispatchChecked) => {
    console.log("updateServiceMessageList:-serviceChecked:" + IsServiceChecked + "---dispatchChecked:" +   IsDispatchChecked);
    const msgList = [];
    this.state.orgMessages.forEach((data) => {
      if ((IsServiceChecked && IsDispatchChecked) || (!IsServiceChecked && !IsDispatchChecked)) {
        msgList.push(data);
      } else if (IsServiceChecked && !IsDispatchChecked &&  data.messageType === 1) {
        msgList.push(data);
      } else if (!IsServiceChecked && IsDispatchChecked && data.messageType === 2) {
        msgList.push(data);
      }
      msgList.sort((a, b) =>
        a.priority > b.priority
          ? -1
          : a.priority === b.priority && a.datestamp > b.datestamp
          ? -1
          : 1
      );
      this.setState({ messages: msgList });
    });
  };

  boxWidth = "auto";
  renderBox = (key) => {
    const value = this.state.tripStats[key];
    if ( key === "companyID" || key === "ExceptionActive" || key === "ServiceAlerts") {
      return;
    }
    if (key === "CalledInToday") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxWarning"}
          border={1}
          sx={{ border: "1px solid #F78133" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#F78133" }}>
              <RouteIcon></RouteIcon>
            </div>
            <div style={{ color: "#F78133" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                TRIPS Today
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "LastHour") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxWarning"}
          border={1}
          sx={{ border: "1px solid #F78133" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#F78133" }}>
              <BoltIcon></BoltIcon>
            </div>
            <div style={{ color: "#F78133" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                Trips Last Hr
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "RushToday") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxSecondary"}
          border={1}
          sx={{ border: "1px solid #9585EC" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#9585EC" }}>
              <RouteIcon></RouteIcon>
            </div>
            <div style={{ color: "#9585EC" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                Rush
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }   
    if (key === "RushLastHour") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxSecondary"}
          border={1}
          sx={{ border: "1px solid #9585EC" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#9585EC" }}>
              <BoltIcon></BoltIcon>
            </div>
            <div style={{ color: "#9585EC" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                Rush Last Hr 
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "PoddedBurnsToday") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxDanger"}
          border={1}
          sx={{ border: "1px solid #DE3562" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#DE3562" }}>
              <WatchLaterIcon></WatchLaterIcon>
            </div>
            <div style={{ color: "#DE3562" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                LATE
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "BurnsLastHour") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxDanger"}
          border={1}
          sx={{ border: "1px solid #DE3562" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#DE3562" }}>
              <WatchLaterIcon></WatchLaterIcon>
            </div>
            <div style={{ color: "#DE3562" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                Late Last Hr 
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }    
    if (key === "Burning") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxDanger"}
          border={1}
          sx={{ border: "1px solid #DE3562" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#DE3562" }}>
              <WatchLaterIcon></WatchLaterIcon>
            </div>
            <div style={{ color: "#DE3562" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                Late Not Del
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "DeliveredToday") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxPrimary"}
          border={1}
          sx={{ border: "1px solid #13ACB1" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#13ACB1" }}>
              <CheckCircleIcon></CheckCircleIcon>
            </div>
            <div style={{ color: "#13ACB1" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                PODS
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "DeliveredLastHour") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxPrimary"}
          border={1}
          sx={{ border: "1px solid #13ACB1" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#13ACB1" }}>
              <CheckCircleIcon></CheckCircleIcon>
            </div>
            <div style={{ color: "#13ACB1" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                PODS Last Hr
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "DCTinNextHour") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxSecondary"}
          border={1}
          sx={{ border: "1px solid #9585EC" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#9585EC" }}>
              <CheckCircleIcon></CheckCircleIcon>
            </div>
            <div style={{ color: "#9585EC" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                DCT Next Hr
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "DCTRestOfDay") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxSecondary"}
          border={1}
          sx={{ border: "1px solid #9585EC" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#9585EC" }}>
              <CheckCircleIcon></CheckCircleIcon>
            </div>
            <div style={{ color: "#9585EC" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                DCT TODAY
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (key === "DCTFutureDates") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxSecondary"}
          border={1}
          sx={{ border: "1px solid #9585EC" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#9585EC" }}>
              <CheckCircleIcon></CheckCircleIcon>
            </div>
            <div style={{ color: "#9585EC" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                DCT FUTURE
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }    
    if (key === "DriversLoggedIn") {
      return (
        <Box
          style={classes.itemBox}
          minWidth={this.boxWidth}
          className={"statBox statBoxPrimary"}
          border={1}
          sx={{ border: "1px solid #13ACB1" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: "#13ACB1" }}>
              <PersonPinIcon></PersonPinIcon>
            </div>
            <div style={{ color: "#13ACB1" }}>
              <Typography color="inherit" style={classes.boxCaption}>
                DRIVERS
              </Typography>
            </div>
          </Box>
          <Box>
            <Typography color="white" style={classes.boxNumber}>
              {value}
            </Typography>
          </Box>
        </Box>
      );
    }
  };

  removeMessage = (serviceQueID) => {
    const tempList = this.state.messages.filter((object) => {
      return object.serviceQueID !== serviceQueID;
    });
    this.setState({ messages: tempList });
  };

  renderDataTime = (params) => {
    return <CounterUpTimer dateTimeStamp={params.value} />;
  };

  renderClearButton = (params) => {
    return (
      <IconButton
        className="iconButtonClear"
        size={"small"}
        onClick={(e) => {
          HttpRequest.clearServiceQueue(
            this.state.accessToken,
            params.row.serviceQueID
          ).then(
            async (body) => {
              console.log("clearServiceQueue -" + JSON.stringify(body.data));
              this.removeMessage(params.row.serviceQueID);
              //setTimeout(() =>{ pollData(); }, 2000);
              //window.location.href='/dashBoard';
            },
            function errorCallback(err) {
              alert("Failed to clearServiceQueue. Error is : " + err);
            }
          );
        }}
      >
        <DeleteIcon />
      </IconButton>
    );
  };

  renderMediaButton = (params) => {
    return (
      <div>
        <IconButton className="iconButtonMedia" size={"small"}>
          <AttachmentIcon />
        </IconButton>
        <IconButton className="iconButtonMedia" size={"small"}>
          <PlayCircleOutlineIcon />
        </IconButton>
        <IconButton className="iconButtonMedia" size={"small"}>
          <HeadphonesIcon />
        </IconButton>
      </div>
    );
  };

  renderClaimButton = (params) => {
    return (
      <div
        className="claimButtonWrapper"
        onClick={(e) => {
          // console.log("claimServiceQueue -" + JSON.stringify(params.row));
          HttpRequest.claimServiceQueue(
            this.state.accessToken,
            params.row.serviceQueID
          ).then(
            async (body) => {
              try {
                console.log("claimServiceQueue -" + JSON.stringify(body.data));
                console.log("claimServiceQueue -job is " + params.row.jobid);
                if (body.data.ResultCode === 1 || body.data.ResultCode === 2) {
                  //window.location.href='/jobDetail/' + params.row.jobid;
                  if ( params.row.jobid !== null &&   params.row.jobid !== "" &&  params.row.jobid !== "0") {
                    let inxURL = "/jobdetail/" + params.row.jobid;
                    window.open(inxURL, "_blank");
                  } else {                  
                    setTimeout(() => {this.pollData();}, 2000);
                  }
                }
              } catch (e) {
                console.log("claimServiceQueue exception:" + e);
              }
            },
            function errorCallback(err) {
              alert("Failed to claimServiceQueue. Error is : " + err);
            }
          );
        }}
      >
        <div className="claimButtonValue"> {params.row.priority}</div>
        <svg
          className={
            params.row.priority > 200
              ? "claimButtonIcon priorityTop"
              : params.row.priority >= 150 && params.row.priority < 199
              ? "claimButtonIcon priorityHigh"
              : params.row.priority >= 101 && params.row.priority < 149
              ? "claimButtonIcon priorityMedium"
              : "claimButtonIcon priorityLow"
          }
          width="auto"
          height="48px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M21 3a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-5.5a2.5 2.5 0 1 0 0-5V4a1 1 0 0 1 1-1h18z" />
          </g>
        </svg>
      </div>
    );
  };

  renderProcessing = (params) => {
    return <Checkbox disabled checked={params.row.processing} />;
  };

  renderJobID = (params) => {
    return <a className="jobIDLink">{params.value}</a>;
  };

  renderPriority = (params) => {
    return (
      <span
        className={
          params.row.priority > 200
            ? "priorityTop"
            : params.row.priority >= 150 && params.row.priority < 199
            ? "priorityHigh"
            : params.row.priority >= 101 && params.row.priority < 149
            ? "priorityMedium"
            : "priorityLow"
        }
      >
        {params.value}{" "}
      </span>
    );
  };

  handleOnCellClick = (params) => {
    console.log(params.field + "---" + params.row.jobid);
    if (params.field === "jobid") {
      if (
        params.row.jobid !== null &&
        params.row.jobid !== "" &&
        params.row.jobid != "0"
      ) {
        let inxURL = "/jobdetail/" + params.row.jobid;
        window.open(inxURL, "_blank");
      } else {
        this.setState({ openWarningDialog: true });
      }
    }
  };

  renderDrNum = (num) => {
    return (
      <div>
        {/* <NavLink href = {'/driver/'+ num.value}>{num.value}</NavLink> */}
        <a
          className="driverNumLink"
          onClick={(e) => {
            window.location.href = "/driver/" + num.value;
          }}
        >
          {num.value}
        </a>
        {num.value && (
          <IconButton
            onClick={(e) => {
              this.setState({ selectedDriver: num.value });
              setTimeout(() => {
                this.setState({ msgBoxOpen: true });
              }, 500);
              //this.setState({msgBoxOpen:true});
            }}
          >
            <ReplyIcon />
          </IconButton>
        )}
      </div>
    );
  };

  msgBoxClose = () => {
    this.setState({ msgBoxOpen: false });
  };

  handleMsgInputChange = (event) => {
    console.log("handleMsgInputChange", event.target.value);
    this.setState({ msgToDriver: event.target.value });
  };

  messageSubmit = () => {
    console.log("sendDriverMessage -" + this.state.selectedDriver);
    console.log("sendDriverMessage msg is -" + this.state.msgToDriver);
    //this.setState({openMessageBox: false});
    this.setState({ msgBoxOpen: false });

    HttpRequest.sendDriverMessage(
      this.state.accessToken,
      this.state.selectedDriver,
      this.state.companyID,
      undefined,
      this.state.msgToDriver
    ).then(
      async (body) => {
        console.log("sendDriverMessage -" + JSON.stringify(body.data));
      },
      function errorCallback(err) {
        alert("Failed to sendDriverMessage. Error is : " + err);
      }
    );
  };

  columns = [
    {
      field: "jobid",
      headerName: "Job ID",
      width: 100,
      renderCell: this.renderJobID,
    },
    {
      field: "processingUser",
      headerName: "User",
      width: 120,
    },
    {
      field: "clear",
      headerName: "Clear",
      width: 100,
      renderCell: this.renderClearButton,
    },
    {
      field: "claim",
      headerName: "Claim",
      width: 100,
      renderCell: this.renderClaimButton,
    },    
    {
      field: "messageText",
      headerName: "Message Text",
      width: 500,
    },
    {
      field: "drNum",
      headerName: "Driver #",
      width: 150,
      renderCell: this.renderDrNum,
    },
    {
      field: "descr",
      headerName: "Description",
      width: 180,
    },
    {
      field: "datestamp",
      headerName: "DateTime",
      width: 150,
      renderCell: this.renderDataTime,
    },   
    {
      field: "companyID",
      headerName: "CoID",
      width: 160,
    },
    {
      field: "media",
      headerName: "Media",
      width: 100,
      renderCell: this.renderMediaButton,
    },
  ];

  render() {
    return (
      <div className="root">
        <AppBar position="static" className="appBarDark" style={classes.appBar}>
          <Box display="flex" flexWrap="nowrap" flexDirection="column" m={1}>
            <Box
              display="flex"
              // flexGrow = {1}
              justifyContent="stretch"
              border={0}
              className={"statsWrapper"}
              sx={{ height: "auto" }}
            >
              <Box
                style={classes.itemBox}
                minWidth={this.boxWidth}
                className={"statBox statBoxPrimary"}
                border={1}
                sx={{ border: "1px solid #13ACB1" }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ color: "#13ACB1" }}>
                    <AssignmentIndIcon></AssignmentIndIcon>
                  </div>
                  <Typography color="primary" style={classes.boxCaption}>
                    ASSIGN
                  </Typography>
                </Box>
                <Box>
                  <Typography color="white" style={classes.boxNumber}>
                    {this.state.tripAssignStats}
                  </Typography>
                </Box>
              </Box>
              <Box
                style={classes.itemBox}
                minWidth={this.boxWidth}
                className={"statBox statBoxDanger"}
                border={1}
                sx={{ border: "1px solid #DE3562" }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ color: "#DE3562" }}>
                    <NotificationsIcon></NotificationsIcon>
                  </div>
                  <div style={{ color: "#DE3562" }}>
                    <Typography color="inherit" style={classes.boxCaption}>
                      ALERTS
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <Typography color="white" style={classes.boxNumber}>
                    {this.state.tripStats.ServiceAlerts}
                  </Typography>
                </Box>
              </Box>

              <Box
                style={classes.itemBox}
                minWidth={this.boxWidth}
                className={"statBox statBoxWarning"}
                border={1}
                sx={{ border: "1px solid #F78133" }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ color: "#F78133" }}>
                    <WarningIcon></WarningIcon>
                  </div>
                  <div style={{ color: "#F78133" }}>
                    <Typography color="inherit" style={classes.boxCaption}>
                      EXCEPTION
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <Typography color="white" style={classes.boxNumber}>
                    {this.state.tripStats.ExceptionActive}
                  </Typography>
                </Box>
              </Box>

              <Box
                style={classes.itemBox}
                minWidth={this.boxWidth}
                className={"statBox statBoxWarning"}
                border={1}
                sx={{ border: "1px solid #F78133" }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ color: "#F78133" }}>
                    <PaidIcon></PaidIcon>
                  </div>
                  <div style={{ color: "#F78133" }}>
                    <Typography color="inherit" style={classes.boxCaption}>
                      SALES
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <Typography color="white" style={classes.boxNumber}>
                    {this.state.tripStats.ExceptionActive}
                  </Typography>
                </Box>
              </Box>

              <Box
                style={classes.itemBox}
                minWidth={this.boxWidth}
                className={"statBox statBoxWarning"}
                border={1}
                sx={{ border: "1px solid #F78133" }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ color: "#F78133" }}>
                    <ModeOfTravelIcon></ModeOfTravelIcon>
                  </div>
                  <div style={{ color: "#F78133" }}>
                    <Typography color="inherit" style={classes.boxCaption}>
                      D/C
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <Typography color="white" style={classes.boxNumber}>
                    {this.state.tripStats.ExceptionActive}
                  </Typography>
                </Box>
              </Box>

              {Object.keys(this.state.tripStats).map((key) =>
                this.renderBox(key)
              )}
            </Box>
            <div style={{ padding: "5px 15px" }}>
              <FormGroup row>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        label="Service"
                        color="primary"
                        size="small"
                        onChange={(val) => {
                          console.log(
                            "service is checked : " + val.target.checked
                          );
                          this.setState({ serviceChecked: val.target.checked });
                          this.updateServiceMessageList(
                            val.target.checked,
                            this.state.dispatchChecked
                          );
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingRight: "15px",
                        }}
                        className={classes.Text}
                      >
                        SERVICE
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        label="Exception"
                        // checked = {localSearchParams.exception}
                        size="small"
                        onChange={(val) => {
                          console.log(
                            "dispatch is checked : " + val.target.checked
                          );
                          this.setState({
                            dispatchChecked: val.target.checked,
                          });
                          this.updateServiceMessageList(
                            this.state.serviceChecked,
                            val.target.checked
                          );
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingRight: "15px",
                        }}
                        className={classes.Text}
                      >
                        DISPATCH
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        label="Sales"
                        // checked = {localSearchParams.exception}
                        size="small"
                        onChange={(val) => {
                          console.log(
                            "dispatch is checked : " + val.target.checked
                          );
                          this.setState({
                            dispatchChecked: val.target.checked,
                          });
                          this.updateServiceMessageList(
                            this.state.serviceChecked,
                            val.target.checked
                          );
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingRight: "15px",
                        }}
                        className={classes.Text}
                      >
                        SALES
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        label="DC"
                        // checked = {localSearchParams.exception}
                        size="small"
                        onChange={(val) => {
                          console.log(
                            "dispatch is checked : " + val.target.checked
                          );
                          this.setState({
                            dispatchChecked: val.target.checked,
                          });
                          this.updateServiceMessageList(
                            this.state.serviceChecked,
                            val.target.checked
                          );
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingRight: "15px",
                        }}
                        className={classes.Text}
                      >
                        D/C
                      </Typography>
                    }
                  />
                </FormControl>
              </FormGroup>
            </div>
          </Box>
        </AppBar>

        <div className="darkDataGrid">
          <DataGridPro
            autoHeight={true}
            pageSize={100}
            rows={this.state.messages}
            columns={this.columns}
            onCellClick={this.handleOnCellClick}
            onSelectionModelChange={(ids) => {
              try {
                const selectedIDs = new Set(ids);
                const selectedRowData = this.state.messages.filter((row) =>
                  selectedIDs.has(row.id)
                );
                this.setState({ selectedDriver: selectedRowData[0].drNum });
              } catch (e) {
                console.log(e);
              }
            }}
          />
        </div>
        <div>
          <Dialog
            className="darkDialog"
            open={this.state.msgBoxOpen}
            onClose={this.msgBoxClose}
          >
            <DialogTitle>Send Message</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus={true}
                variant="outlined"
                margin="dense"
                label="Message"
                type="text"
                fullWidth
                onChange={this.handleMsgInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.messageSubmit}>Submit</Button>
              <Button color="secondary" onClick={this.msgBoxClose}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <CustomDialog
            open={this.state.openWarningDialog}
            onClose={() => this.setState({ openWarningDialog: false })}
            hasCloseBtn={false}
            dialogMessage="No JobID Attached"
            dialogTitle="Warning"
            dialogAction={() => {
              this.setState({ openWarningDialog: false });
            }}
          />
        </div>
        <footer>
          <Copyright textColor='white'/>
        </footer>
      </div>
    );
  }
}

export default DashBoard;
