import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useHistory  } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Copyright from "../Copyright";
import Typography from "@material-ui/core/Typography";
import httpRequest from "../HttpService";
import AddressCard from "./components/addressCard";
import CameraButton from "./components/cameraButton";
import TextInput from "../components/inputFields/TextInput";
import Gallery from "./components/gallery";
import StatusBar from './components/StatusBar';
import moment from "moment";
import InfoField from "./components/infoField";
import logo from "./images/logo_west-direct.jpg";
import CustomIconButton from "../components/inputFields/CustomIconButton";
import {Grid, Stepper, Step, StepLabel,Button, ButtonGroup,InputAdornment } from "@material-ui/core";
import { Divider} from '@mui/material';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import "../components/sideIcon/sideIcon.css";
import "../jobDetail/jobDetail.css";
import MapboxPanel from "../components/MapboxPanel";
import StreetViewMap from "../components/streetViewSideBySideMap";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TripFunctionDialog from "./components/tripFunctionDialog";
import TripDriverList from "./components/tripDriverList";
import TripHistoryList from "./components/tripHistoryList";
import TripRatingList from "./components/tripRatingList";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputField from "./components/inputField";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker,MuiPickersUtilsProvider,} from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustomDialog from "./components/dialog";
import CallerField from "./components/callerField";
import ReferenceField from "./components/referenceFields";
import moment_tz from "moment-timezone";
import NoticeEmitter from "../NoticeUtil";
import { Snackbar, Alert } from "@mui/material";


export default function JobDetail(props) { 
    const history = useHistory(); 

    const classes = {
        root: {
          backgroundColor: "transparent",
        },
        gallery: {   
        },
        drawerList: {
          width: 360,
          padding: 0,
        },
        drawerWrapper: {
          backgroundColor: "transparent",
          top: "64px",
          left: "38px",
        },
        drawerHeader: {
          display: "flex",
          alignItems: "center",
          padding: 1,
          justifyContent: "space-between",
        },
        toolbarGridItem: {
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "15px",
        },
        jobDetailLabel: {
          fontSize: 12,
          fontWeight: 500,
        },
      }    
    
      const accessToken = localStorage.getItem("access_token");
      const {jobId} = useParams();
      const [companyLogo,setCompanyLog] = useState(logo);
      const [galleryVisible,setGalleryVisible] = useState(false);
      const [photoArray,setPhotoArray] = useState([]);      
      const [showContent,setShowContent] = useState(0); // 0-map 1-pic 2-history 3-driver&rating
      const [view,setView] = useState("map");  //street
      const [addr,setAddr] = useState("delivery");  //street
      const [GPSUpdateButtonText,setGPSUpdateButtonText] = useState("Update Delivery GPS");  //street
      const [isMenuOpen,setIsMenuOpen] = useState(false);
      const [viewAddress,setViewAddress] = useState({
        companyName: "",
        lat: "",
        long: "",
        address: "",
      });
      const [category,setCategory] = useState("View");  
      const [viewFunctions,setViewFunctions] = useState([]);
      const [dispatchFunctions,setDispatchFunctions] = useState([]);
      const [serviceFunctions,setServiceFunctions] = useState([]);
      const [ratingFunctions,setRatingFunctions] = useState([]);
      const [accountList,setAccountList] = useState([]);
      const [TripServiceNoteList,setTripServiceNoteList] = useState([]);
      const [rateChoices,setRateChoices] = useState([]);
      const [callerList,setCallerList] = useState([]);
      const [packageList,setPackageList] = useState([]);
      const [referenceList,setReferenceList] = useState([]);
      const [steps,setSteps] = useState([]);
      const [isUploadFile,setIsUploadFile] = useState(false);
      const [isEdit,setIsEdit] = useState(false);
      const [dialogIsOpen,setDialogIsOpen] = useState(true);
      const [openUpdatingDialog,setOpenUpdatingDialog] = useState(false);
      const [openSnack,setOpenSnack] = useState(false);
      const [updatingItmeStr,setUpdatingItmeStr] = useState("");  
      const [messageInfo,setMessageInfo] = useState("");  
      const [TripServiceAccountNotes,setTripServiceAccountNotes ] = useState("");  
      const [gpsData,setGpsData] = useState({});
      const [currentAccount,setCurrentAccount] = useState({});
      const [currentRate,setCurrentRate] = useState({});
      const [updatingReferences,setUpdatingReferences] = useState({});
      const [updatingItem,setUpdatingItem] = useState({});
      const [tripFunction,setTripFunction] = useState({parameters: [],});
      const [latestUpdatedPostion,setLatestUpdatedPostion] = useState(null);
      const [updateGPSSource,setUpdateGPSSource] = useState(null);
      const [interval,setInterval] = useState(null);
      const [order,setOrder] = useState({});
      const [orderDTCallIn,setOrderDTCallIn]=useState(null);
      const [orderDTDCT,setOrderDTDCT]=useState(null);
      const [orderSCT,setOrderSCT]=useState(null);
      const [locationUpdateDialog,setLocationUpdateDialog] = useState(false);    
        



      useEffect(() => {
        let token = localStorage.getItem("access_token");
        if (token === undefined || token === null) window.location.href = "/";    
        loadTripDetails(jobId);
        getTripFunctions(jobId);

        NoticeEmitter.addListener("refreshJobDetail", (data) => {
          console.log("==NoticeEmitter refreshJobDetail==" + data);
          loadTripDetails(jobId);
        });
        return () => {NoticeEmitter.removeListener('refreshJobDetail', () => {});};
      }, [jobId]);

      const handleMenu = (val) => {
        setIsMenuOpen(true);
        setCategory(val);        
      };

      const loadTripDetails = useCallback((jobId) => { 
        setTimeout(() => {
          if (jobId) {
            const shortId = jobId.slice(-5);  // Get last 5 characters
            document.title = `${shortId}`;
          }
        }, 100);

        getTripServiceNotes(jobId);    
        httpRequest.getTrip(accessToken, jobId).then((response) => {
          console.log(JSON.stringify(response.data));
          if(response.data.isLattice)
            // window.open(`/latticeJobDetail/${jobId}`, "_blank");
              history.push(`/latticeJobDetail/${jobId}`); 

          var data = response.data;
          try {        
            data.dtCallIn_display = data.dtCallIn?.toString().substring(0, 19).replace("T", " ");
            data.dtDCT_display = data.dtDCT?.toString().substring(0, 19).replace("T", " ");
    
            if (data.dtDelivered) 
              data.dtDelivered_display = data.dtDelivered.toString().substring(0, 19).replace("T", " ");
            if (data.eta)
              data.eta_display = data.eta.toString().substring(0, 19).replace("T", " ");
            if (data.URL)
              data.FedexTrackNum = data.URL.substring(data.URL.length - 12);
    
            var images = JSON.parse(data.ImageURLs);
            if (images) {
              var imageList = [];
              images.forEach((value) => {
                let fileExt = value.url.split(".").pop();
                value.savedTimeUTC = new Date(moment(value.savedTimeUTC).toDate() - new Date().getTimezoneOffset() * 60000); //UTC -- Locatime display
                value.savedTime = moment(value.savedTimeUTC).format("YY-MM-DD HH:mm");
                value.id = imageList.length;   
                value.IsImageOrPDF = !(value.url.includes(".doc") || value.url.includes(".docx") || value.url.includes(".xls") || value.url.includes(".xlsx") ||value.url.includes(".csv"));         
                value.IsImage = !(value.url.includes(".doc") || value.url.includes(".docx") ||value.url.includes(".pdf") || value.url.includes(".xls") || value.url.includes(".xlsx") || value.url.includes(".csv"));
               
                value.fileType = (value.url.includes(".doc") || value.url.includes(".docx")) ? "doc" :(value.url.includes(".xls") || value.url.includes(".xlsx")) ? "xls"
                  :value.url.includes(".pdf") ? "pdf": value.url.includes(".csv") ? "csv" :"image";                
                value.fileExt = value.url.split(".").pop().includes("net/") ? "png" : fileExt;
                imageList.push(value);
              });
              setPhotoArray(imageList);             
            }
            setOrder(data);
            setViewAddress({
                companyName: data.cCompanyName,
                lat: data.cLat,
                long: data.cLong,
                address: data.cAddress,
              });
            setAddr("delivery");
            setGPSUpdateButtonText("Update Delivery GPS");         

            setOrderDTCallIn(moment(moment_tz.tz(data.dtCallIn_display, "Canada/Mountain").toDate()).format());            
            setOrderDTDCT(moment(moment_tz.tz(data.dtDCT_display, "Canada/Mountain").toDate()).format());
            if (data.eta) 
                setOrderSCT(moment_tz.tz(data.eta_display, "Canada/Mountain").toDate());  

            if (data.logo?.data) {
              var binary = "";
              var bytes = new Uint8Array(data.logo.data);
              var len = bytes.byteLength;
              for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
              }
              const imageSrc = "data:image/png;base64," + btoa(binary);
              setCompanyLog(imageSrc);             
            }
    
            getGPSTracking();    
            getTripAccountList(data.jobID, data.ACCOUNT);
            httpRequest.getOrderTrack(jobId).then((response) => {
                try {
                    const tempTrackList = response.data.map((option,index) => {
                         option.id=index;
                         return option;
                    });
                    setSteps(tempTrackList);                
                } catch (e) {
                   console.log("getOrderTrack exception:" + e);
                }
            });
            setIsUploadFile(false);           
    
            if (data.Status < 8) {
              if (interval === null) {
                let _interval = setInterval(() => {getGPSTracking();}, 30000);
                setInterval(_interval);              
              }
            } else {
              if (interval !== null) {
                clearInterval(interval);
                setInterval(null); 
              }
            }    
            getRateChoices(data.SER_AREA, data.SER_TIME);
          } catch (err) {
            console.log("Order not found. Please try again");
          }
        });
      }, []);
    
    const getRateChoices = (ser_area, ser_time) => {
        httpRequest.getRateChoice(accessToken,jobId).then(async (response) => {
            console.log("getRateChoices",JSON.stringify(response.data));
            try {               
                let newRows = response.data.map((item,i) => {
                  return {
                     ...item,
                     id: i,
                     service: item.ser_area + item.ser_time,
                  };
                }); 
                //console.log("getRateChoices",JSON.stringify(newRows));
                setRateChoices(newRows);     
                var _currentRate = newRows.find((obj) => {return (obj.ser_area === ser_area && obj.ser_time === ser_time);});
                if (_currentRate !== undefined){
                    console.log("getRateChoices currentRate is " + JSON.stringify(_currentRate));
                    setCurrentRate(_currentRate);
                }
                //setCurrentRate(newRows.find((obj) => {return (obj.ser_area === order.SER_AREA && obj.ser_time === order.SER_TIME);}));                  
            } catch (e) { console.log("getRateChoices exception", e); }
        });
    };
    
    const getTripServiceNotes = (jobId) => {
        httpRequest.getTripServiceNotes(accessToken, jobId).then((body) => {
            try {
              console.log("getTripServiceNotes", JSON.stringify(body.data))
              let tripServiceNoteList = [];
              if (body.data.length > 0) {
                let tripServiceAccountNotes = "";
                body.data.forEach((row) => {
                  if (row.source?.toLowerCase().includes("account") && row.notes)
                    tripServiceAccountNotes += row.notes;
                  else if (row.notes !== null && row.notes !== "")
                    tripServiceNoteList.push(row);
                });
                setTripServiceNoteList(tripServiceNoteList);
                setTripServiceAccountNotes(tripServiceAccountNotes);                
              }
            } catch (e) {console.log("getTripServiceNotes exception", e);}
        });
    };

    const getPackageList = () => {
        httpRequest.getTripPackageDescriptions(accessToken, jobId).then((response) => {
            setPackageList(response.data);
        });
    };
    
    const getGPSTracking = () => {
        httpRequest.getJobGPS(jobId).then((response) => {
          setGpsData(response.data);  
          if (response.data.TripLocation.includes("Delivered")) {
            if (interval !== null) {
              clearInterval(interval);
              setInterval(null);
            }
          }
        });
    };

    /* [{"account":"9905","AccountName":"DIRECTIT SERVICE FAIL."},{"account":"9910","AccountName":"DirectIT Group"},{"account":"M05102","AccountName":"C.R. Wall & Co. Inc."}] */
    const getTripAccountList = (jobId, account) => {
      httpRequest.getTripAccountList(accessToken, jobId).then((body) => {
        try {
          console.log(`getTripAccountList account is ${account}`);
          console.log("getTripAccountList", JSON.stringify(body.data));
          setAccountList(body.data);        
          const currentAcct = body.data.find(function (obj) {return obj.account === account.trim();}); 
          console.log("getTripAccountList currentAccount is " + JSON.stringify(currentAcct))
          if(currentAcct !== undefined && currentAcct !== null)
            setCurrentAccount(currentAcct);            
        } catch (e) {
          console.log("getTripAccountList exception",e);
        }
      });
    };

    const getReferenceList = () => {
        httpRequest.getReferenceFields(accessToken, jobId).then((body) => {
            console.log("getReferenceFields", JSON.stringify(body.data));
            setReferenceList(body.data);
            body.data.forEach((item) => {
              if (item.typeDesc === "DropDown")
                getReferenceDropDown(item.refID);
            });
          });
      };
    
    const getReferenceDropDown = (id) => {
        httpRequest.getReferenceDropDown(accessToken, id).then((response) => {
            console.log("getReferenceDropDown", JSON.stringify(response.data));
            let refList = referenceList;
            var objIndex = refList.findIndex((obj) => obj.refID == id);
            refList[objIndex].ddValues = response.data;
            setReferenceList(refList);
        });
    };   
    
    const getUserEmails = () => {
        httpRequest.getUserEmails(order.ACCOUNT).then((response) => {
            setCallerList(response.data);
        });
      };
    
    const removeCallerFromList = (caller) => {
        let nameList = callerList.filter(function (obj) {
          return obj.username !== caller;
        });
        setCallerList(nameList);
    };
    
    const rendAccountServiceNotes = () => {
        return TripServiceNoteList.map((item) => (
          <Alert variant="filled"  severity="info"  className={"fieldJobNote"}  icon={false}>
            <div className={"speechBubble"}>{item.source}</div>
            <div className={"notes"}>{item.notes}</div>
          </Alert>
        ));
    };
    
    const updateOrderFields = (jobId) => {
        try {
            let updateObj = updatingItem;
            updateObj.jobID = jobId;
            if (updateObj.dtCallIn)
               updateObj.dtCallIn = moment(updateObj.dtCallIn).toDate() - new Date().getTimezoneOffset() * 60000;
            if (updateObj.dtDCT)
               updateObj.dtDCT = moment(updateObj.dtDCT).toDate() - new Date().getTimezoneOffset() * 60000;
            if (updateObj.sct)
               updateObj.sct = moment(updateObj.sct).toDate() - new Date().getTimezoneOffset() * 60000;
    
            if (updateObj.RATE) {
                updateObj.ser_area = updateObj.RATE.ser_area;
                updateObj.ser_time = updateObj.RATE.ser_time;    
                let _order = order;
                _order.SER_AREA = updatingItem.RATE.ser_area;
               _order.SER_TIME = updatingItem.RATE.ser_time;
               _order.RATE = updatingItem.RATE.Rate;
               setOrder(_order);           
            }         
            if (updateObj.hasOwnProperty("del_inst") && updateObj.del_inst.length === 0) 
               updateObj.del_inst = "  "; 
            console.log("updateJobFields",JSON.stringify(updateObj));

            httpRequest.updateJobFields(accessToken, updateObj).then((body) => {
                console.log("updateJobFields",JSON.stringify(body.data));
                if(updateObj.hasOwnProperty("caller")){
                    var callers = callerList.find(function (obj) {
                        return obj.username === updateObj.caller;
                    });
                    console.log("updateObj has caller",JSON.stringify(callers));
                    if(callers === undefined){
                        httpRequest.postUserEmails(order.ACCOUNT, updateObj.caller).then(async (res) => {
                           console.log("postUserEmails",JSON.stringify(res.data));
                        });
                    }
                }    
                postUpdatedReferences(jobId);
                httpRequest.updateJobRateFields(accessToken, updateObj).then((body) => {
                    console.log("updateJobRateFields",JSON.stringify(body.data));
                    setOpenUpdatingDialog(true);
                },
                function errorCallback(err) {
                    console.log("Failed to update Job rate field: " + err);
                });
            },
            function errorCallback(err) {console.log("Failed to update job field: " + err);});
        } catch (e) {console.log("updateOrderFields exception" + e);}
    };
    
    const postUpdatedReferences = (jobID) => {
        try {
          if (JSON.stringify(updatingReferences) !== "{}") {
            for (let key in updatingReferences){
              if (updatingReferences.hasOwnProperty(key)) {  
                var reference = {
                  refID: key,
                  value: updatingReferences[key],
                };
                console.log("postUpdatedReferences- " + JSON.stringify(reference));
                httpRequest.postReferenceFields(accessToken, jobID, reference).then((body) => {
                      console.log("postReferenceFields",JSON.stringify(body.data));
                },
                function errorCallback(err) {
                    alert("Failed to update reference editTripService field.");
                });  
              }           
            }
          }
        } catch (e) {console.log("postUpdatedReferences exception" + e);}
    };

    const getTripFunctions = (jobId) => {
        httpRequest.getTripFunctions(accessToken, jobId).then((response) => {
            try {
               let viewFuncs = [];
               let dispatchFuncs = [];
               let serviceFuncs = [];
               let ratingFuncs = [];    
                console.log(JSON.stringify(response.data));
                response = response.data;
                console.log(JSON.stringify(response[1]));
                for (const x in response[1][0]) {
                    let row = { functionText: x };
                    if (x !== "rating" && x!== 'Tags') {
                        if (x === "Drivers") 
                            row.functionText = "Drivers&rating";                  
                        viewFuncs.push(row);
                    }
                }
                console.log(viewFunctions);    
                response[2].map((row) => {
                    console.log(row);
                    if (row.section.includes("Dispatch")) 
                       dispatchFuncs.push(row);                
                    if (row.section.includes("Service")) 
                       serviceFuncs.push(row);                
                    if (row.section.includes("Rating")) 
                       ratingFuncs.push(row);                 
                });
                setViewFunctions(viewFuncs);
                setDispatchFunctions(dispatchFuncs);
                setServiceFunctions(serviceFuncs);
                setRatingFunctions(ratingFuncs);    
            } catch (e) {console.log("getTripFunctions exception", e);}
          });
      };

    const  toggleGallery = (visible) => {
        console.log("toggleGallery - " + visible);
        setGalleryVisible(visible);
        if (!visible && isUploadFile)
           loadTripDetails(jobId);
    };

    const renderStepContentText = (label) => {
        const str = " Click Here to Track >>> ";
        return (
          <div>
            <Typography>{label.bigText}</Typography>
            <Typography variant="caption">
              {label.smallText + moment(label.timestamp_utc).format("MM-DD") + " @ " + moment(label.timestamp_utc).format("LT")};
            </Typography>
            {label.img_url && (
              <span><a onClick={() => {window.open(label.img_http_link, "_blank");}}>{" "}{label.img_text}{" "}</a>
                <div>
                  {" "}
                  <img style={{ width: "80px", height: "60px" }} src={label.img_url} onClick={() => {window.open(label.img_http_link, "_blank");}}/>
                  {" "}
                </div>
              </span>
            )}
          </div>
        );
    }
    
    const currentDateTime = () => {
        var current = new Date();
        return new Date(current.getFullYear(), current.getMonth(), current.getDate(), current.getHours(),current.getMinutes());
    };

    const performTripFunction = (action, category) => {
        try {
          let menuOptions = [];
          menuOptions = (category === "View") ? viewFunctions : (category === "Dispatch")? dispatchFunctions : (category === "Service")? serviceFunctions : ratingFunctions;         
          if (category !== "View") {
            menuOptions.map((row) => {
              if (row.functionText === action.functionText) 
                 processFunction(row);
            });
          } else {
            setShowContent(action.functionText === "map"? 0: action.functionText === "pictures"? 1 : action.functionText === "History"? 2 : 3)
            setIsMenuOpen(false);            
          }
        } catch (e) {console.log("performTripFunction exception:",e);}
    };
    
    const processFunction = (row) => {
        //console.log( " processFunction row :" + JSON.stringify(row));
        let functionParamsList = [];
        if (row.paramaters !== null && row.paramaters.length !== 0) {
            row.paramaters = row.paramaters.split("[").join("{").split("]").join("}");         
            row.paramaters = row.paramaters.replace('"values":{', '"values":[');
            row.paramaters = row.paramaters.replace('},"default"', '],"default"');
            var obj = JSON.parse(row.paramaters);
            var keys = Object.keys(obj);
            keys.map((key) => {
                var funcObj = obj[key];
                if (funcObj.type === "dropdown") {
                    functionParamsList.push({
                        label: key,
                        prompt: funcObj.prompt,
                        type: funcObj.type,
                        default: funcObj.default,
                        length: funcObj.length,
                        mo: funcObj.mo,
                        list: funcObj.values,
                        value: "",
                    });
                } else if (funcObj.type === "datetime") {
                    functionParamsList.push({
                        label: key,
                        prompt: funcObj.prompt,
                        type: funcObj.type,
                        length: funcObj.length,
                        mo: funcObj.mo,
                        date_val: currentDateTime(),
                        time_val: currentDateTime(),
                        value: currentDateTime(),
                    });
                } else if (funcObj.type === "decimal") {
                    functionParamsList.push({
                        label: key,
                        prompt: funcObj.prompt,
                        type: funcObj.type,
                        length: funcObj.length,
                        mo: funcObj.mo,
                        value: "",
                        precision: funcObj.precision,
                    });
                } else if (funcObj.type === "checkbox") {
                    functionParamsList.push({
                        label: key,
                        prompt: funcObj.prompt,
                        type: funcObj.type,
                        length: funcObj.length,
                        default: funcObj.default ? funcObj.default : true,
                        mo: funcObj.mo,
                        value: funcObj.default ? funcObj.default : true,
                    });
                } else {
                    functionParamsList.push({
                        label: key,
                        prompt: funcObj.prompt,
                        type: funcObj.type,
                        length: funcObj.length,
                        mo: funcObj.mo,
                        value: "",
                    });
                }
            }); 
            if (functionParamsList.length > 0) 
                postFunctionParameters(functionParamsList,row.procedureName, row.hasOverride,row.RefreshAfter, row.functionText);  
        } else {
          if (row.procedureName === null) alert("No procedure for this function!");
          else {
            httpRequest.doTripFunction(accessToken, jobId, [], row.procedureName).then(async (body) => {
                  //alert("procedure is done!");
                setMessageInfo(`${row.procedureName}  procedure is done!`)
                setOpenSnack(true);                 
                if (row.RefreshAfter) {
                    loadTripDetails(jobId);
                    if(category === "Rating")
                      NoticeEmitter.emit("refreshRating", "");
                } else {
                    getTripFunctions(jobId);
                }            
            },
            function errorCallback(err) {
                alert("Failed to finish procedure"+ row.procedureName +". Error is : " + err);
            });
          }
        }
    };
    
    const postFunctionParameters = (paramsList,procedureName, isOverride, refreshAfter, functionText) => {
        setTripFunction({
            parameters: paramsList,
            procedureName: procedureName,
            isOverride: isOverride,
            refreshAfter: refreshAfter,
            jobId: jobId,
            accessToken: accessToken,
            functionText:functionText
          });
        setDialogIsOpen(true);       
    };    

    const renderSideMenu = () => {
        return (
          <div class="side-icons">
            <button  class="btn btn-warning slide-icon" onClick={() => handleMenu("View")} onMouseOver={() => handleMenu("View")}>
              <i class="fa fa-eye" aria-hidden="true"></i>
              <span>View</span>
            </button>
            <button  class="btn btn-danger slide-icon" onClick={() => handleMenu("Dispatch")} onMouseOver={() => handleMenu("Dispatch")}>
              <i class="fa fa-truck" aria-hidden="true"></i>
              <span>Dispatch</span>
            </button>
            <button class="btn btn-success slide-icon" onClick={() => handleMenu("Service")} onMouseOver={() => handleMenu("Service")}>
              <i class="fa fa-wrench" aria-hidden="true"></i>
              <span>Service</span>
            </button>
            <button class="btn btn-primary slide-icon"  onClick={() => handleMenu("Rating")} onMouseOver={() => handleMenu("Rating")}>
              <i class="fa fa-sliders" aria-hidden="true"></i>
              <span>Rating</span>
            </button>
          </div>
        );
    };
    
    const renderMenu = (category) => {
        let menuOptions =  category === "View"?viewFunctions:category === "Dispatch"?dispatchFunctions:category === "Service"?serviceFunctions:ratingFunctions;        
        return (
          <Drawer
            style={classes.drawerWrapper}  className={"transparentDrawer"}
            elevation={0}  anchor="left"  hideBackdrop="true" variant="persistent"  open={isMenuOpen}  onClose={() => setIsMenuOpen(false)}>
            <div style={classes.drawerList} className={`${category} drawerList`} onMouseLeave={() => setIsMenuOpen(false)}>
              <div style={classes.drawerHeader} className={"drawerHeader"}>
                <Typography variant="h5" width="70%">{category}</Typography>
                <IconButton aria-label="delete"> <CloseIcon onClick={() => setIsMenuOpen(false)}  onMouseOver={() => setIsMenuOpen(false)}/></IconButton>
              </div>
              <Card elevation={0}>
                <List dense={true} disablePadding={true}>
                  {menuOptions.map((action) => (
                    <div className={"listItemWrapper"}>
                      <ListItem button key={action.functionText} divider={true} onClick={() => performTripFunction(action, category)}>
                        <ListItemText  primary={action.functionText} style={{ textTransform: "capitalize" }}/>
                      </ListItem>
                    </div>
                  ))}
                </List>
              </Card>
            </div>
          </Drawer>
        );
    };

    const setUpdatedRefValue = (name, value) => {
        console.log(`setUpdatedRefValue name is ${name}  value is ${value}`);
        setUpdatingReferences((prevState) => {
            return {
              ...prevState,
              [name]: value,        
            };
        });       
    };    
    
    const handleValueChange = (event) => {
        console.log(`handleValueChange - ${event.target.name} - ${ event.target.value}`);  
        setUpdatingItem((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value === ""?" ":event.target.value,        
            };
        });        
    };      
    
    const  handleGPSChange = (lat, lng, source) => {
        setLatestUpdatedPostion({lat: lat, lng: lng,});
        setUpdateGPSSource(source);       
    };

    const updateGPS = () => {
        var paramsList = [];
        paramsList.push({
          label: "lat",
          prompt: "Latitude",
          type: "decimal",
          mo: "Latitude",
          precision: "6",
          value: latestUpdatedPostion.lat.toFixed(6),
        });
        paramsList.push({
          label: "long",
          prompt: "Longitude",
          type: "decimal",
          mo: "Longitude",
          precision: "6",
          value: latestUpdatedPostion.lng.toFixed(6),
        });
        var spName = addr.includes("delivery")? "in5.trip_updateConGPS" : "in5.trip_updateShipGPS";
        httpRequest.doTripFunction(accessToken, jobId,paramsList,spName).then(async (body) => {
              console.log("doTripFunction-" + body.data);
            },
            function errorCallback(err) {
              alert("Failed to finish trip_assign procedure. Error is : " + err);
            }
        );
        let _order = order;
        if (addr.includes("delivery")) {
            _order.cLat = latestUpdatedPostion.lat.toFixed(6);
            _order.cLong = latestUpdatedPostion.lng.toFixed(6);
        } else {
           _order.sLat = latestUpdatedPostion.lat.toFixed(6);
           _order.sLong = latestUpdatedPostion.lng.toFixed(6);
        }
        setOrder(_order);
    };
    
    const  updateOrder = () => {
        try {
            console.log("======updateOrder- " + JSON.stringify(updatingItem));
            if(updatingItem.hasOwnProperty("caller") && updatingItem.caller === ''){
                setUpdatingItmeStr("Please input caller!");
                setOpenUpdatingDialog(true);
                return;
            }
            setIsEdit(false);         
            let keyArry = Object.keys(updatingItem);
            let info = "";  
            keyArry.map((item) => {
              if (item === "del_inst") info += "Instruction,";
              else if (item === "pieces") info += "Piece number,";
              else if (item === "emailConf") info += "Email,";
              else if (item === "sigReq") info += "No Signature Required,";
              else if (item === "dtCallIn") info += "Call In,";
              else if (item === "dtDCT") info += "DCT,";
              else if (item === "Desc") info += "Package Type,";
              else info += item + ",";
            });         
            if (JSON.stringify(updatingReferences) !== "{}")
               info += "Reference";
            setUpdatingItmeStr(`updated items:  ${info}`);
            updateOrderFields(jobId);
        } catch (e) {
          console.log(`updateOrder exception- ${e}`);
        }
    };

    const handlePiecesChange = (event) => {
        if (event.target.value < 0) 
          event.target.value = 0;      
        if (event.target.value.toString().length > 3) {
          let temp = event.target.value.toString().substring(0, 3);
          event.target.value = temp;
        }
    };
    
    const  handleMaxLengthFiveChange = (event) => {
        if (event.target.value < 0) 
          event.target.value = 0;        
        if (event.target.value.toString().length > 5) {
          let temp = event.target.value.toString().substring(0, 5);
          event.target.value = temp;
        }
    };
    
    const handlerEditClick = () => {
        console.log("handlerEditClick currentRate is " + JSON.stringify(currentRate));
        setIsEdit(true);
        getUserEmails();
        getPackageList();
        getReferenceList();
        setUpdatingItem({});
        setUpdatingReferences({});       
    };

    const renderLabel = (text) => {               
        return (<Typography variant="button"  color="primary" style={classes.jobDetailLabel}> {text} </Typography>)
    };    

    const handleView = (event) => {   
        const _view = view === "street"? "map":"street";
        setView(_view);
    };

    const handleAddr = (event) => {
        setViewAddress({
            companyName: addr === "pickup"? order?.cCompanyName : order?.ShipperName,
            lat: addr === "pickup"?order?.cLat : order?.sLat ,
            long: addr === "pickup"?order?.cLong : order?.sLong,
            address: addr === "pickup"?order?.cAddress : order?.sAddres,
        })
        setAddr(addr === "pickup"?"delivery":"pickup");
        setView("map");
        setGPSUpdateButtonText(addr === "pickup"?"Update Delivery GPS":"Update Shipper GPS");

        //  this.setState({ view: "map" }, () => this.setState({ view: "street" }));  ????      
    };

    const renderDatetimePicker = (name, value, setStateFunc) => {               
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker name={name} className="editableJobField" fullWidth
              format="yyyy-MM-dd HH:mm"  value={value}
              onChange={(input) => {
                setUpdatingItem((prevState) => {
                    return {...prevState, [name]: input,};
                }); 
                setStateFunc(input);                       
              }}
            />
          </MuiPickersUtilsProvider>
        )
    };   

    const renderMultilineText = (name, val, backgroundColor, border) =>{
        return(
            <TextInput inputType="text"  name={name}  multiline  minRows={1}  hideLabel={true}
                value={val} disabled
                style={{
                   backgroundColor: backgroundColor,
                   borderRadius: "4px",
                   color: "#fff",
                   border: border,
                }}
            />
        )
    }

    const renderFieldWithInputAdornment  = (val, sign, position) =>{
        return(
            position==="start"?
            <InfoField value={val}
                InputProps={{
                    startAdornment: (
                        <InputAdornment style={{ margin: "auto", marginRight: 10}}>
                          {sign}
                        </InputAdornment>
                    )                    
                }}
            />:
            <InfoField value={val}
                InputProps={{
                    endAdornment: (
                        <InputAdornment style={{ margin: "auto", marginRight: 5}}>
                          {sign}
                        </InputAdornment>
                    ),
                }}
            />
        )
    }

    const renderEditFieldWithInputAdornment  = (name, type, val, sign, position, onChangeHandler, onBlurHandler) =>{
        return(
            position==="start"?
            <InputField name={name} type={type}
                defaultValue={val}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                InputProps={{
                   startAdornment: (
                   <InputAdornment style={{ margin: "auto", marginRight: 10 }}>
                    {sign}
                   </InputAdornment>),
                }}
            />:
            <InputField name={name} type={type}
                defaultValue={val}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                InputProps={{
                    endAdornment: (
                       <InputAdornment style={{ margin: "auto", marginRight: 10 }}>
                       {sign}
                       </InputAdornment>),
                }}
            />
        )
    }

    const renderDropdown = (name, value, options, keyName, labelName) => {               
        return (
            <TextField name={name}  variant="outlined" select  size="small" className="editableJobField"
               defaultValue={value}
               onChange={(event) => {
                  setUpdatingItem((prevState) => {
                    return {...prevState, [name]: event.target.value,};
                   });                                            
                }}
            >
            {options.map((item) => (
              <MenuItem key={item[keyName]} value={item[keyName]}>
                {item[labelName]}
              </MenuItem>
            ))}
          </TextField>   
        )
    };   

    return (
        <>
        <div className="root">
          <TripFunctionDialog  tripFunction={tripFunction}  open={dialogIsOpen} handleDialogClose={() => setDialogIsOpen(false)}/>
          <StatusBar order={order} isEdit={isEdit} setIsEdit ={setIsEdit} updateOrder={updateOrder} jobId={jobId} companyLogo={companyLogo} handlerEditClick={handlerEditClick}/> 
          {/* <Divider sx={{ bgcolor: "#FFFFFF", height: 3, width: '100%',margin:1 }} />          */}
          <Grid  container  direction="row"  justifyContent="flex-end"  alignItems="flex-start"  style={{ paddingLeft: "38px", margin: "0", width: "100%" }}  spacing={1}>
            <Grid  item  container direction="row" justifyContent="center" alignItems="flex-start"  spacing={2} style={{ margin: "0", width: "100%" }} sm={12} lg={9}>
              <>
                <Grid item xs={12} sm={12} md={12}>
                  {TripServiceNoteList.length > 0 && rendAccountServiceNotes()}
                </Grid>
                <Grid  item xs={12} sm={12} md={6} className="shipperCardBlock darkCardBlock">
                  {order && (
                    <AddressCard
                      jobId={jobId}
                      type="shipper"
                      accessToken={accessToken}
                      accountNumber={order?.ACCOUNT}
                      coord={{lat: order?.sLat, long: order?.sLong}}
                      data={{
                        companyName: order?.sCompanyName,
                        address: order?.sAddress,
                        suite: order?.sSuite,
                        quad: order?.sQuad,
                        city: order?.sCity,
                        prov: order?.sProv,
                        country: order?.sCountry,
                        postal: order?.sPostal,
                        contactName: order?.sContact,
                        contactPhone: order?.sPhone,
                        contactID: order?.shipContactID,
                        addressID: order?.shipAddressID,
                        lat: order?.sLat,
                        long: order?.sLong,
                      }}
                    />
                  )}
                </Grid>
                <Grid  item xs={12} sm={12} md={6} className="consigneeCardBlock darkCardBlock">
                  {order && (
                    <AddressCard
                      jobId={jobId}
                      type="consignee"
                      accessToken={accessToken}
                      accountNumber={order?.ACCOUNT}
                      coord={{lat: order?.cLat,long: order?.cLong,}}
                      data={{
                        companyName: order?.cCompanyName,
                        address: order?.cAddress,
                        suite: order?.cSuite,
                        quad: order?.cQuad,
                        city: order?.cCity,
                        prov: order?.cProv,
                        country: order?.cCountry,
                        postal: order?.cPostal,
                        contactName: order?.cContact,
                        contactPhone: order?.cPhone,
                        contactID: order?.conContactID,
                        addressID: order?.conAddressID,
                        lat: order?.cLat,
                        long: order?.cLong,
                      }}
                    />
                  )}
                </Grid>              
              </>

              {view === "street" && showContent < 2 && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    {viewAddress.lat !== "" && viewAddress.long !== "" && (
                        <StreetViewMap
                          address={viewAddress.companyName}
                          lat={viewAddress.lat}
                          lng={viewAddress.long}
                          type={addr}
                          sLat={order.sLat}
                          sLong={order.sLong}
                          cLat={order.cLat}
                          cLong={order.cLong}
                          images={photoArray}
                          orderStatus={order.Status}
                          dtDelivered={order.dtDelivered}
                          delLat={order.delLat}
                          delLong={order.delLong}
                          handleGPSChange={handleGPSChange}
                        />
                      )}
                  </Grid>
                </>
              )}

              {view === "map" && showContent < 2 && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    {order && (
                      <MapboxPanel
                        orderID={jobId}
                        orderStatus={order.Status}
                        dtDelivered={order.dtDelivered}
                        delLat={order.delLat}
                        delLong={order.delLong}
                        gpsData={gpsData}
                        images={photoArray}
                      />
                    )}
                  </Grid>
                </>
              )}
              {showContent < 2 && (
                <Grid item md={12} container direction="row" justifyContent="flex-end" alignItems="center">
                  <Typography noWrap variant="button" color="primary" style={classes.jobDetailLabel}>View</Typography>
                  &ensp;
                  <ButtonGroup size="small" variant="outlined" aria-label="outlined button group">
                    <Button onClick={handleView} color="primary" disabled={view === "map"}
                      style={view === "map"? { color: "#3BA264", borderColor: "#3BA264" }: {}}>
                      {view === "map" && (<i class="fa fa-check" aria-hidden="true"></i>)}
                      Map
                    </Button>
                    <Button  onClick={handleView} color="primary" disabled={view === "street"}
                      style={view === "street"? { color: "#3BA264", borderColor: "#3BA264" } : {}}>
                      {view === "street" && (<i class="fa fa-check" aria-hidden="true"></i>)}
                      Street
                    </Button>
                  </ButtonGroup>
                  &emsp;
                  <Typography noWrap variant="button" color="primary" style={classes.jobDetailLabel}>Type</Typography>
                  &ensp;
                  <ButtonGroup  size="small" variant="outlined" aria-label="outlined button group">
                    <Button onClick={handleAddr} color="primary" disabled={addr === "delivery" ||view === "map"}
                      style={addr === "delivery" && view !== "map"? { color: "#3BA264", borderColor: "#3BA264" }: {}}>
                      {addr === "delivery" && (<i class="fa fa-check" aria-hidden="true"></i>)}
                      Delivery
                    </Button>
                    <Button  onClick={handleAddr} color="primary" disabled={ addr === "pickup" || view === "map"}
                      style={addr === "pickup" && view !== "map" ? { color: "#3BA264", borderColor: "#3BA264" } : {}}>
                      {addr === "pickup" && (<i class="fa fa-check" aria-hidden="true"></i>)}
                      Pickup
                    </Button>
                  </ButtonGroup>
                  &emsp;
                  {view === "street" && (
                    <Button  size="small"  color="primary" variant="contained" onClick={() => { setLocationUpdateDialog(true) }}>
                      {GPSUpdateButtonText}
                    </Button>
                  )}
                </Grid>
              )}
              <>
                <Grid item md={12}>                   
                    {/* <Stepper activeStep={activeStep} alternativeLabel> */}
                    <Stepper alternativeLabel>
                    {steps !== undefined && steps?.map((label) => (
                        <Step key={label.bigText} completed={label.filled}>
                          <StepLabel> {renderStepContentText(label)} </StepLabel>
                        </Step>
                      ))}
                  </Stepper>
                </Grid>
                {showContent === 2 && (<Grid item md={12}><TripHistoryList  jobId={jobId}  accessToken={accessToken}></TripHistoryList></Grid>)}
                {showContent === 3 && (<Grid item md={12}><TripDriverList  jobId={jobId}  accessToken={accessToken}></TripDriverList></Grid>)}
                {showContent === 3 && (<Grid item md={12}><TripRatingList  jobId={jobId}  accessToken={accessToken} isLattice={false}></TripRatingList></Grid>)}
              </>
            </Grid>
            <Grid item container direction="row"  justifyContent="flex-end" alignItems="center"
               sm={12} lg={3} style={{ margin: "0", width: "100%" }} spacing={1}
               className="darkForm editJobForm">
              <Grid item sm={12} className="imagesCardBlock darkCardBlock">
                <CameraButton  images={photoArray}  onChange={toggleGallery}/>
              </Grid>
              <Grid item sm={12}>
                {galleryVisible && photoArray && (
                  <div className="containerGallery" style={classes.gallery}>
                    <Gallery  accessToken={accessToken} jobId={jobId} photoArray={photoArray} setUploadFileFlag={setIsUploadFile}/>
                  </div>)}
              </Grid>
              {/* Switch account */}
              <Grid item sm={4}> {renderLabel("Switch Account")} </Grid>
              <Grid item sm={8}>
                {!isEdit && currentAccount.account && (<InfoField value={currentAccount.AccountName} />)}
                {currentAccount.account && isEdit && (renderDropdown("account", currentAccount.account, accountList, "account","AccountName"))}
              </Grid>
              {/* account info */}
              <Grid item sm={12}>
                {renderLabel("Account Info")}   
                {renderMultilineText("account info", TripServiceAccountNotes,"#F78133","1px solid #F78133")}  
              </Grid>
              {/* sct callin */}
              <Grid item sm={6}>
                {renderLabel("SCT")}                  
                {!isEdit && (<InfoField value={order?.eta_display} />)}
                {isEdit && (renderDatetimePicker("sct",orderSCT,setOrderSCT))}
              </Grid>
              <Grid item sm={6}>
                {renderLabel("Callin")} 
                {!isEdit && (<InfoField value={order?.dtCallIn_display} />)}
                {isEdit && (renderDatetimePicker("dtCallIn",orderDTCallIn,setOrderDTCallIn))}               
              </Grid>
              {/* dct delivery service */}
              <Grid item sm={6}>
                {renderLabel("DCT")} 
                {!isEdit && (<InfoField value={order?.dtDCT_display} />)}
                {isEdit && (renderDatetimePicker("dtDCT",orderDTDCT,setOrderDTDCT))}                
              </Grid>
              <Grid item sm={6}>
                {renderLabel("Delivery Service")} 
                {!isEdit && (<InfoField value={ `${order?.SER_AREA} - ${order?.SER_TIME}  [ ${order?.RATE} ]` }/>)}                
                {isEdit && (
                    <TextField  name="service"  variant="outlined" select size="small" className="editableJobField"  value={currentRate.service}
                      onChange={(event) => {
                      let item = rateChoices.find((o) => o.service === event.target.value);
                      setCurrentRate(item);
                      setUpdatingItem((prevState) => {
                          return {...prevState, ["RATE"]: item,};
                        }); 
                      }}
                    >
                    {rateChoices.map((item) => (
                      <MenuItem key={item.id} value={item.service}>{item.descr}</MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              {/* dct delivery service */}
              <Grid item sm={2}>{renderLabel("Caller")}</Grid>
              <Grid item sm={10}>
                {!isEdit && (<InfoField value={order?.CALLER} />)}
                {isEdit && (
                  <CallerField
                    caller={order?.CALLER}
                    callers={callerList}
                    account = {order?.ACCOUNT}
                    removeCallerFromList = {removeCallerFromList}
                    refreshCallList = {getUserEmails}
                    handleValueChange={handleValueChange}
                  />
                )}
              </Grid>
              {/* Notes */}
              <Grid item sm={12}>
                {renderLabel("Instructions")}              
                {!isEdit && (renderMultilineText("notes", order?.DEL_INST,"#DE3562","1px solid #DE3562"))}
                {isEdit && (
                  <InputField inputType="text"  name="del_inst"  inputLength={200}
                    defaultValue={order?.DEL_INST}
                    onChange={(event) => {
                      const value = event.target.value;
                      event.target.value = value.replace(/[^a-zA-Z0-9-?+$%@&<>|#?,.!<>()/*'\\;: ]/g, "");                
                    }}
                    onBlur={handleValueChange}
                  />
                )}
              </Grid>
              {/* status location */}
              <Grid item sm={6}>
                {renderLabel("Status")} 
                <InfoField  name="status" value={order?.StatusString}/>
              </Grid>
              <Grid item sm={6}> 
                {renderLabel("Location")}
                <InfoField name="location" value={order?.locationString}/>
              </Grid>
              {/* distance billing reference */}
              <Grid item sm={6}>
                {renderLabel("Distance")}
                <InfoField name="distance" value={order?.drivDistance}/>
              </Grid>
              {!isEdit && (
                <Grid item sm={6}>
                  {renderLabel("Billing Reference")}                  
                  <InfoField name="service" value={order?.CUST_REF}/>
                </Grid>
              )}
              {isEdit && (
                <ReferenceField referenceList={referenceList} setUpdatedRefValue={setUpdatedRefValue}/>
              )}
              {/* notification email */}
              <Grid item sm={6}>
                {renderLabel("Notification Email")}               
                {!isEdit && (<InfoField value={order?.emailConf} />)}
                {isEdit && (<InputField
                    inputType="text"
                    name="emailConf"
                    defaultValue={order?.emailConf}
                    onBlur={handleValueChange}
                  />
                )}
              </Grid>
              <Grid item sm={6}>
                {renderLabel("Package Type")}               
                {!isEdit && (<InfoField name="package type"  value={order?.DESC}/>)}
                {isEdit && ( renderDropdown("Desc", order.DESC, packageList, "description","description"))}
              </Grid>
              {/* pieces weight */}
              <Grid item sm={6}>
                {renderLabel("Pieces")}                
                {!isEdit && ( renderFieldWithInputAdornment(order?.Pieces,"#", "start"))}
                {isEdit && (renderEditFieldWithInputAdornment("pieces", "number",order?.Pieces,"#", "start", handlePiecesChange, handleValueChange))}
              </Grid>
              <Grid item sm={6}>
                {renderLabel("Weight")} 
                {!isEdit && (renderFieldWithInputAdornment(order?.weight,"lbs","end"))}
                {isEdit && (renderEditFieldWithInputAdornment("weight", "number",order?.weight,"lbs","end", handleMaxLengthFiveChange, handleValueChange))}
              </Grid>
              {/* signature required value */}
              <Grid item sm={6}>
                {/* <Typography variant="button" color="primary" style={{fontSize:12, fontWeight: 500}}>Signature Required</Typography> */}
                {!isEdit && order?.sigReq!== undefined && (
                  <FormControlLabel
                    control={<Checkbox  color="primary" checked={!order?.sigReq} />}
                    label={renderLabel("No Signature Required")}                    
                  />
                )}
                {isEdit && (
                  <FormControlLabel
                    control={
                      <Checkbox name="sigReq" color="primary" defaultChecked={!order?.sigReq}
                        onChange={(val) => {
                          let obj = updatingItem;
                          obj[val.target.name] = !val.target.checked;
                          setUpdatingItem(obj);                          
                        }}
                      />
                    }
                    label={renderLabel("No Signature Required")}
                  />
                )}
              </Grid>
              <Grid item sm={6}>
                {renderLabel("Value")}               
                {!isEdit && (renderFieldWithInputAdornment(order?.DECLARED || 0,"$", "start"))}
                {isEdit && (renderEditFieldWithInputAdornment("declared", "number", order?.DECLARED || 0, "$", "start", handleMaxLengthFiveChange, handleValueChange))}
              </Grid>
              {/* tracking number */}
              <Grid item sm={4}>{renderLabel("Tracking Number")}</Grid>
              <Grid item sm={8} style={{ display: "flex", flexShrink: 0 }}>
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0}>
                  <Grid item md={1}>
                    <CustomIconButton
                      icon={"FileCopyOutlinedIcon"}
                      label="Copy"
                      onClick={() =>navigator.clipboard.writeText(`https://webx.directx.ca/TrackNo/${order?.TrackNumber}`)}
                      variant="contained"
                      color="primary"
                      selected={false}
                      rippleDisabled
                    />
                  </Grid>
                  <Grid item md={11}>
                    <InfoField name="tracking number" style={{ marginLeft: 10 }} value={order?.TrackNumber}/>
                  </Grid>
                </Grid>
              </Grid>
              {/* third party tracking */}
              <Grid item sm={4}>{renderLabel("Additional Routing")}</Grid>
              <Grid item sm={8}>
                {!isEdit && (
                  <Grid container direction="row" justifyContent="center" alignItems="stretch"  spacing={0}>
                    <Grid item md={1}>
                      {order?.URL && (
                        <CustomIconButton
                          icon={"FileCopyOutlinedIcon"}
                          label="Copy"
                          style={{ marginRight: 10 }}
                          onClick={() => navigator.clipboard.writeText(order?.URL)}
                          variant="contained"
                          color="primary"
                          selected={false}
                          rippleDisabled
                        />
                      )}
                    </Grid>
                    <Grid item md={11}>
                      <InfoField value={order?.URL} style={{ marginLeft: 10 }}/>
                    </Grid>
                  </Grid>
                )}
                {isEdit && (<InputField inputType="text" name="url" defaultValue={order?.URL} onBlur={handleValueChange}/>)}              
              </Grid>
              {/* delivered time */}
              <Grid item sm={4}>{renderLabel("Delivered Time")}</Grid>
              <Grid item sm={8}><InfoField name="delivered time" value={order?.dtDelivered_display}/></Grid>
              {/* proof of delivery */}
              <Grid item sm={4}> {renderLabel("Proof of Delivery")}</Grid>
              <Grid item sm={8}><InfoField  name="proof of delivery" value={order?.PROOF_DEL}/></Grid>
              <Grid item sm={4}>
                {order?.priorJobID && (
                  <Button style={{ minHeight: 35, marginLeft: 5 }}  variant="contained" color="primary"
                    onClick={() => {
                      window.location.href ="/jobDetail/" + order.priorJobID;
                      //history.push(`/jobDetail/${order.priorJobID}`);
                    }}>
                    Prev
                  </Button>
                )}
                {order?.NextJobID && (
                  <Button style={{ minHeight: 35, marginLeft: 5 }} variant="contained" color="primary"
                    onClick={() => { 
                      window.location.href = "/jobDetail/" + order.NextJobID;
                      //history.push(`/jobDetail/${order.order.NextJobID}`);
                    }}>
                    Next
                  </Button>
                )}
              </Grid>
              <Grid item sm={8}></Grid>
              {/* Signature */}
              <Grid item sm={4}></Grid>
              <Grid item sm={8}><img src={order?.signatureURL}  style={{ width: "190px" }}/></Grid>
            </Grid>
          </Grid>
          {renderSideMenu()}
          {renderMenu(category)}
          {/* Custom Dialogs */}
          <CustomDialog
            open={openUpdatingDialog}
            onClose={() => setOpenUpdatingDialog(false)}
            hasCloseBtn={false}
            dialogMessage={updatingItmeStr}
            dialogTitle="Warning"
            dialogAction={() => {
              setOpenUpdatingDialog(false);
              setTimeout(() => {loadTripDetails(jobId);}, 1500);
            }}
          />
          <CustomDialog
            open={locationUpdateDialog}
            onClose={() => setLocationUpdateDialog(false)}
            hasCloseBtn={true}
            dialogTitle="Warning"
            dialogMessage={
              updateGPSSource === "dropman" ? "Are you sure to update GPS Coordinates?"  : updateGPSSource === "photo"
                ? "Are you sure to update GPS Coordinates to the same as photo location?": "Are you sure to update GPS Coordinates to the same as signature location?"
            }
            dialogAction={() => {                
              setLocationUpdateDialog(false);
              if (latestUpdatedPostion === null) return;
              updateGPS();
            }}
          />
          <Snackbar  open={openSnack}  autoHideDuration={1500} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClose={() => { setOpenSnack(false);}}
          >
            <Alert onClose={() => { setOpenSnack(false);}}  severity="info" sx={{ width: "100%" }}>
              {messageInfo}
            </Alert>
          </Snackbar>
          <footer>
            <Copyright textColor='white'/>
          </footer>
        </div>
      </>     
    ); 

}