import React, { useState, useEffect } from 'react';
import {Button,ButtonGroup,Checkbox,Typography} from '@mui/material';
import httpRequest from '../../HttpService';
import NoticeEmitter from '../../NoticeUtil';
import AddIcon from "@mui/icons-material/AddComment";
import CalculateIcon from '@mui/icons-material/Calculate';

import HttpRequest from "../../HttpService";
import DataGrid, {Column,Selection, Scrolling} from 'devextreme-react/data-grid';
import "devextreme/dist/css/dx.dark.css";
import AddRateItmeDialog from "./AddRateItemDialog"
import EditItemDialog from "./EditItemDialog";

const TripRatingList = props => {    

    const [tripRatingList, setTripRatingList] = useState([]);
    const [tableHeight, setTableHieght] = useState(200);
    const [selectedRate,setSelectedRate] = useState();
    const [dialogIsOpen, setDialogIsOpen] = useState(false); 
    const [editOpen, setEditOpen] = useState(false); 
    const [availableRateItemList, setAvailableRateItemList] = useState([]);
    const [selectedRatingItem, setSelectedRatingItem] = useState({});


    // const getTripAvailableRatingItems = async (type) => {
    //     HttpRequest.getTripAvailableRatingItems(props.accessToken,props.jobId).then(async (response) => {
    //        console.log('getTripAvailableRatingItems',JSON.stringify(response.data)); 
    //        var tempList =[];           
    //        await response.data.forEach((element) => {
    //             console.log("getTripAvailableRatingItems-element: ", element);   
    //             try{   
    //                const params = element.variableDefinition !== null ? JSON.parse(element.variableDefinition): null; 
    //                element.params = params;
    //                tempList.push(element);
    //             }
    //             catch(e){
    //                 console.log('getTripAvailableRatingItems-exception-' + e);
    //             }
    //         });  
    //         console.log('getTripAvailableRatingItems-RateItem',JSON.stringify(tempList))
    //         setAvailableRateItemList(tempList);            
    //     })
    // }

    
    useEffect(() => {  
        getTripRate();   
        //getTripAvailableRatingItems();    
    },[]);

    NoticeEmitter.addListener('refreshJobDetail', (data) => {
        console.log('====TripRatingList======NoticeEmitter refreshJobDetail===============' + data);
        setTimeout(function(){ getTripRate(); }, 500);       
    });

    NoticeEmitter.addListener('refreshRating', (data) => {
        console.log('====TripRatingList======NoticeEmitter refreshRating===============' + data);
        setTimeout(function(){ getTripRate(); }, 500);          
    });

    const getTripRate =() =>{
        var ratingList =[];
        httpRequest.getTripRating(props.accessToken,props.jobId).then(body =>{
            console.log("getTripRate-" + JSON.stringify(body.data))
            if(body.data.length !== 0){                
                body.data.forEach((value) => {
                    value.datestamp = value.datestamp?value.datestamp.substring(0,19).replace("T"," "):'';
                    value.id = ratingList.length;
                    value.value = value.value?.toFixed(2);
                    value.CalcAmount = value.CalcAmount?.toFixed(2);
                    value.CalcPayOn = value.CalcPayOn?.toFixed(2);
                    ratingList.push(value);
                });     
                setTripRatingList(ratingList); 
                setTableHieght(180 + 40* ratingList.length);
                setSelectedRate(ratingList[0]);     
            }
        });
    }
  
    const renderExemptDEV = params => {
        //console.log("renderExemptDEV", params);
        return(
            (params.data['mzrid'])? (<Checkbox sx={{
                color: "#007E82",
                '&.Mui-checked': {
                  color: "white",
                 },
               }}
                onClick={(event)=>{
                    var rate = params.data;
                    console.log(event.target.checked + "-------before updateZrateExempt- " + JSON.stringify(rate));
                    rate.exempt =  event.target.checked?1:0;
                    rate.isLocked =  rate.isLocked?1:0;                   
                    console.log("----------updateZrateExempt- " + JSON.stringify(rate));
                    httpRequest.updateZrateExempt(props.accessToken,props.jobId,rate).then(body =>{  
                        console.log("updateZrateExempt- " + JSON.stringify(body));         
                        if(body.data) {
                           console.log("updateZrateExempt- " + JSON.stringify(body.data));
                        }
                        ////getTripRate();
                        setTimeout(function(){ getTripRate(); }, 1000);
                    });       
                }}
                defaultChecked = {params.value}
            />) :(<Typography style={{ marginLeft:"15px" }}> {params.value} </Typography>) 

        )
    }  
 

    const renderIsLocked = params => {
        //console.log("renderIsLocked", params);
        return(
            // (params.data['mzrid'])? (<Checkbox sx={{
            //     color: "#007E82",
            //     '&.Mui-checked': {
            //       color: "white",
            //      },
            //    }}
            //     onClick={(event)=>{
            //         var rate = params.data;
            //         console.log(event.target.checked + "-------before updateZrateExempt- " + JSON.stringify(rate));
            //         rate.isLocked =  event.target.checked?1:0;                   
            //         console.log("----------updateZrateExempt- " + JSON.stringify(rate));
            //         httpRequest.updateZrateExempt(props.accessToken,props.jobId,rate).then(body =>{  
            //             console.log("updateZrateExempt- " + JSON.stringify(body));         
            //             if(body.data) {
            //                console.log("updateZrateExempt- " + JSON.stringify(body.data));
            //             }
            //             ////getTripRate();
            //             setTimeout(function(){ getTripRate(); }, 1000);
            //         });       
            //     }}
            //     defaultChecked = {params.value}
            // />) :(<Typography style={{ marginLeft:"15px" }}> {params.value} </Typography>) 

            <Checkbox sx={{
                color: "#007E82",
                '&.Mui-checked': {
                  color: "white",
                 },
               }}
                onClick={(event)=>{
                    var rate = params.data;
                    console.log(event.target.checked + "-------before updateZrateExempt- " + JSON.stringify(rate));
                    rate.isLocked =  event.target.checked?1:0;   
                    rate.exempt =  rate.exempt?1:0;                       
                    console.log("----------updateZrateExempt- " + JSON.stringify(rate));
                    httpRequest.updateZrateExempt(props.accessToken,props.jobId,rate).then(body =>{  
                        console.log("updateZrateExempt- " + JSON.stringify(body));         
                        if(body.data) {
                           console.log("updateZrateExempt- " + JSON.stringify(body.data));
                        }
                        ////getTripRate();
                        setTimeout(function(){ getTripRate(); }, 1000);
                    });       
                }}
                defaultChecked = {params.value}
            /> 

        )
    }  
    
    const renderDrNum = params => {
        return(
             <a style={{color: "#FFFFFF"}} >{params.data.drNum} </a>           
        )
    }

    const onSelectionChanged = ({selectedRowsData}) =>{
        const data = selectedRowsData[0];
        console.log("onSelectionChanged --" + JSON.stringify(selectedRowsData));
        setSelectedRate(selectedRowsData);
      }

    const RecalculateAndRefresh = () =>{
       httpRequest.doTripReRate(props.accessToken,props.jobId).then(body =>{  
        console.log("RecalculateAndRefresh --" + JSON.stringify(body));
        getTripRate();  
       })
    }

    const updateRateItem = (e) => {
        console.log("updateRateItem --" + JSON.stringify(e.data));
        setSelectedRatingItem(e.data)
        setEditOpen(true)
    }

    const submitRequest = (varJson) => {
        console.log("submitRequest --" + varJson);
        const content = {
            mzrid : selectedRatingItem.mzrid,
            varJSON:  varJson
        }
        httpRequest.updateRatingItems(props.accessToken,props.jobId,content).then((body) =>{
            console.log("postTripAddedTag " + JSON.stringify(body.data));
            setEditOpen(false);
            getTripRate();
        });
    };


    const doAddRate = () =>{
        HttpRequest.getTripAvailableRatingItems(props.accessToken,props.jobId).then(async (response) => {
            console.log('getTripAvailableRatingItems',JSON.stringify(response.data)); 
            var tempList =[];           
            await response.data.forEach((element) => {
                 console.log("getTripAvailableRatingItems-element: ", element);   
                 try{   
                    const params = element.variableDefinition !== null ? JSON.parse(element.variableDefinition): null; 
                    element.params = params;
                    tempList.push(element);
                 }
                 catch(e){
                     console.log('getTripAvailableRatingItems-exception-' + e);
                 }
             });  
             console.log('getTripAvailableRatingItems-RateItem',JSON.stringify(tempList))
             setAvailableRateItemList(tempList);   
             setDialogIsOpen(true);         
         })
    }
    

    return (
        <div className='darkDataGrid' style={{ height: '580px', width: '100%' }}>    
            {props.isLattice && <ButtonGroup  size="small" variant="outlined" aria-label="outlined button group" style={{ marginBottom:10 }}>                       
                    <Button  onClick = {()=>{RecalculateAndRefresh()}} color="primary" style={{ color: "#007E82", borderColor: "#007E82" }}>
                        <CalculateIcon style={{ verticalAlign: "middle", transform: "scale(0.8)" }}/>
                          Recalculate and Refresh
                    </Button>
                    <Button onClick={()=>{doAddRate()}} color="primary" style={{ color: "#007E82", borderColor: "#007E82" }}>
                       <AddIcon style={{ verticalAlign: "middle", transform: "scale(0.8)" }}/>
                          Add Rating Item
                    </Button>
                </ButtonGroup>}  
            <DataGrid
                dataSource={tripRatingList}
                showBorders={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                keyExpr="id"
                rowAlternationEnabled={true}  
                onSelectionChanged={onSelectionChanged}     
                onCellDblClick ={updateRateItem}
                height="calc(100% - 60px)"
            >                    
                 <Scrolling mode="virtual" />
                 <Selection mode="single" />

                <Column dataField="CalcAmount" caption="CalcAmount" alignment="center" width={120}/>
                <Column dataField="CalcPayOn" caption="CalcPayOn" alignment="center" width={120}/>                     
                <Column dataField="exempt" caption="exempt" width={100} alignment="center" cellRender={renderExemptDEV}/>
                {props.isLattice && <Column dataField="isLocked" caption="isLocked" width={100} alignment="center" cellRender={renderIsLocked}/>}
                <Column dataField="Description" caption="Description"  width={200}/>
                <Column dataField="Notes" caption="Notes" width={200}/>  
                <Column dataField="datestamp" caption="Date/Time"  width={200}/>
                <Column dataField="amount" caption="amount" alignment="center" width={120}/>  
                <Column dataField="value" caption="value" alignment="center"  width={120}/>
                <Column dataField="perUnit" caption="perUnit" alignment="center" width={120}/>  
                <Column dataField="freeUnits" caption="freeUnits" alignment="center" width={120}/>
                <Column dataField="untilUnits" caption="untilUnits" alignment="center" width={150}/>  
                <Column dataField="drNum" caption="Driver #" width={100} alignment="center" cellRender={renderDrNum}/>
                <Column dataField="review" caption="review" width={150}/>  
            </DataGrid> 
            <Typography  variant="h6" style={{color:'white'}}> 
                <strong>{props.RateSummaryLine}</strong>
            </Typography>
            <AddRateItmeDialog  accessToken={props.accessToken} jobId={props.jobId} itemList={availableRateItemList}  open={dialogIsOpen} 
              handleDialogClose={() => {setDialogIsOpen(false);}} handleDialogSaveClose={() => {setDialogIsOpen(false); getTripRate();}}/>
            <EditItemDialog  accessToken={props.accessToken} jobId={props.jobId} variableDefinition={selectedRatingItem?.variableDefinition}  open={editOpen} 
              description= {selectedRatingItem?.Description} submitRequest={submitRequest} 
              handleDialogClose={() => {setEditOpen(false);}}/>  
        </div>
    );
}

export default TripRatingList;


